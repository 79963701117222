import React, {useEffect, useRef, useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import ModalIconContent from "./modal-content/modal-icon-content";
import RequestProcessingPreview from "../common/request-processing-preview"
import {Box, Typography} from "@mui/material";
const PreviewContent=(title?: string)=>{
    return(
        <Box>
            <ModalIconContent isDone requiredComp={<Box className="spinner"></Box>}/>
            <br/>
            <Typography>{title ?? 'OCR is processing'}</Typography>
        </Box>
    )
}
const OcrProcessingStatus = (props : { onSave: () => void,onRemoveSelected: ()=>void, title?: string }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);
    const onSubmit = () => {
        props.onSave()
        hideModal()
    }


    return <div ref={modalRef} style={{position: "relative",
        width: 350, minHeight: '350px', display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        <RequestProcessingPreview childComp={PreviewContent(props.title)} semiTitle="Please wait..."/>
    </div>


};

export default OcrProcessingStatus;
