import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import RedactionItem from './RedactionItem';
import CollapsiblePanelGroup from '../CollapsiblePanelGroup';

import './RedactionPageGroup.scss';
import {RedactionPanelContext} from "../RedactionPanel/RedactionPanelContext";
import CustomRedactionContext from "../RedactionPanel/CustomRedactionContext";

const RedactionPageGroup = (props) => {
  const {
    pageNumber,
    redactionItems,
  } = props;
    let checkedMarks = [];
    redactionItems.forEach((result) => {
        if (result.markChecked) {
            checkedMarks.push(result)
        }
    });
    const onChangedCheckedItems = useContext(CustomRedactionContext)

    const checkAllResults = (event) => {
        const checked = event.target.checked;
        let changedCheckedIds = []
        redactionItems.forEach((result) => {
            result.markChecked=checked
            changedCheckedIds.push(result.Id)
        });
        onChangedCheckedItems(changedCheckedIds, checked)
    }

    const allPageMarksChecked = useMemo(() => {
        let res = true;
        redactionItems.forEach((result) => {
            if (!result.markChecked) {
                res=false
                return
            }
        });
        return res
    }, [checkedMarks])

    const header = () => {
    return (
        <div style={{display: "flex", marginLeft: 10}}>
          <input type={"checkbox"}
                 checked={allPageMarksChecked ?? false}
                 onChange={checkAllResults}
                 label={`Page ${pageNumber}`}
                 className="redaction-search-results-page-number"
          />
          Page {pageNumber}
        </div>
    );
  };

  return (
      <CollapsiblePanelGroup
          className="redaction-page-group"
          header={header}
      >
        <div role="list" className="redaction-items">
          {redactionItems.map((redactionItem) => (
              <RedactionItem
                  annotation={redactionItem}
                  key={`${redactionItem.Id}-${pageNumber}`}
          />
        ))}
      </div>
    </CollapsiblePanelGroup>
  );
};

export default RedactionPageGroup;