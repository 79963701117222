import React, { useCallback, useContext } from 'react';
import RedactionItem from './RedactionItem';
import { RedactionPanelContext } from '../../RedactionPanel/RedactionPanelContext';
import WebViewerContext from "../../../../contexts/webviewer-context";

export const defaultNoteDateFormat = 'MMM D YYYY h:mm A';
export const defaultLanguage = 'en'


const RedactionItemContainer = (props) => {
  const { annotation } = props;
  const { setInstance,instance } = useContext(WebViewerContext);

  const { selectedRedactionItemId, setSelectedRedactionItemId } = useContext(RedactionPanelContext);

  const iconColor = annotation.StrokeColor.toString();
  //The category/type can be stored in a few different places. The field trn-redaction-type will be on documents edited in the webviewer and then saved.
  //Finally, in RLS Desktop the category is stored on the title field which is interpretted by webviewer as the author.
  const type = annotation.type || annotation.getCustomData('trn-redaction-type') || instance.Core.annotationManager.getDisplayAuthor(annotation['Author'])
  if (type) {
    annotation.type = type
    annotation.setCustomData('trn-redaction-type', type)
  }

  const onRedactionItemSelection = useCallback(() => {
    instance.Core.annotationManager.trigger('panelRedactionItemSelected', {annotation})
    setSelectedRedactionItemId(annotation.Id);
  }, [annotation]);

  const onRedactionItemDelete = useCallback(() => {
    instance.Core.annotationManager.deleteAnnotations([annotation]);
  }, [annotation]);
  const [isChecked, setIsChecked] = React.useState(false);

  const onCheckChange = (e) => {
    setIsChecked(e.target.checked)
  }

  return (
    <RedactionItem
      dateFormat={defaultNoteDateFormat}
      language={defaultLanguage}
      type={type}
      annotation={annotation}
      iconColor={iconColor}
      onRedactionItemDelete={onRedactionItemDelete}
      onRedactionItemSelection={onRedactionItemSelection}
      isSelected={selectedRedactionItemId === annotation.Id}
      checked={isChecked}
      onCheckChange={onCheckChange}
    />
  );
};

export default RedactionItemContainer;
