  import {
    ANNOTATION_ID_COLUMN,
    ANNOTATION_TYPE, APPLIED_RULES, COMMENTS_COLUMN,
    CREATION_DATE_COLUMN,
    DESCRIPTION_ROW,
    FILE_NAME_COLUMN,
    MATCH_CATEGORY_COLUMN,
    OCCURRENCE_COLUMN,
    ORDERING_ID_COLUMN,
    ORIGINAL_TEXT_COLUMN,
    PAGE_NUMBER_COLUMN,
    PATIENT_ID_COLUMN,
    QUADS, REPLACED_END_INDEX, REPLACED_START_INDEX, REPLACEMENT_METHOD,
    REPLACEMENT_TEXT_COLUMN,
    STANDARDIZED_DATE_COLUMN,
    START_DATE_COLUMN,
    STATUS_COLUMN, STUDY_ID_COLUMN,
    USERNAME
} from "../constants";
import {applyDefaultRules} from "./rules/defaultRules";
  import AnnotationFilterHelpers from "../components/webviewer-multipanel/AnnotationFilterHelpers";


export function generateMarksReport(annotations: any[], fileName: string) {
    const marks = annotations.filter(annotation => annotation.elementName === 'redact')
    return generateReport(marks, fileName)
}

export function generateTransformsReport(annotations: any[], fileName: string) {
    const transforms = annotations.filter(annotation => annotation.elementName === 'redact')
    return generateReport(transforms, fileName)
}

export function generateReport(annotations: any[], fileName: string) {
    applyDefaultRules(annotations);
    const outputData: Map<string, string>[] = []
    const headerRow = Array.from(DESCRIPTION_ROW.keys());
    let occurences: Map<number, Map<string, number>> = new Map<number, Map<string, number>>()
    annotations.forEach((annotation, index) => {
        if (annotation.InReplyTo===null) {//so that replies won't be a separate row in the report
            outputData.push(createReportRow(annotation, fileName, index, occurences))
        }
    })

    const reportRowsArray: string[][] = []
    const descriptionRow = Array.from(DESCRIPTION_ROW.values());
    reportRowsArray.push(descriptionRow)

    outputData.forEach((output) => {
        let array: string[] = []
        headerRow.forEach((key) => {
            array.push(output.get(key)!)
        })
        reportRowsArray.push(array)
    })
    return {headerRow, reportRowsArray}
}


export function createReportRow(annotation: any, fileName: string, index: number, occurences: Map<number, Map<string, number>>) {
    let row: Map<string, string> = new Map();
    row.set(FILE_NAME_COLUMN, fileName)
    row.set(ANNOTATION_TYPE, annotation.elementName)
    row.set(ORIGINAL_TEXT_COLUMN, getOriginalText(annotation))
    row.set(COMMENTS_COLUMN, getCommentValue(annotation))
    row.set(REPLACEMENT_TEXT_COLUMN, annotation.getCustomData(REPLACEMENT_TEXT_COLUMN))
    row.set(PAGE_NUMBER_COLUMN, annotation.getPageNumber())
    row.set(OCCURRENCE_COLUMN, getOccurrenceOfOriginalTextInPage(annotation, occurences))//customdata got by function
    row.set(ANNOTATION_ID_COLUMN, annotation.Id)
    row.set(ORDERING_ID_COLUMN, index.toString())
    row.set(MATCH_CATEGORY_COLUMN, AnnotationFilterHelpers.getCategory(annotation))
    row.set(PATIENT_ID_COLUMN, annotation.getCustomData(PATIENT_ID_COLUMN))
    row.set(STATUS_COLUMN, getStatus(annotation))
    row.set(START_DATE_COLUMN, annotation.getCustomData(START_DATE_COLUMN))//customedata rules
    row.set(STANDARDIZED_DATE_COLUMN, "")
    row.set(STUDY_ID_COLUMN, annotation.getCustomData(STUDY_ID_COLUMN))//customdata rules
    try { //if it is a link the dateCreated value creates an error
        row.set(CREATION_DATE_COLUMN, annotation.DateCreated.toLocaleDateString())
    } catch (e) {
        row.set(CREATION_DATE_COLUMN, '')
    }
    row.set(USERNAME, annotation.getCustomData("author"))
    row.set(APPLIED_RULES, "")
    row.set(QUADS, JSON.stringify(annotation.getRect()).replaceAll(",", ";"))//we replace the ","
    // so that the csv doesn't assume it is a different column

    row.set(REPLACEMENT_METHOD, "")
    row.set(REPLACED_START_INDEX, "")
    row.set(REPLACED_END_INDEX, "")
    return row
}

function getOccurrenceOfOriginalTextInPage(annotation: any, occurences: Map<number, Map<string, number>>) {
    const pageIndex = annotation.getPageNumber()
    if (!occurences.has(pageIndex)) occurences.set(pageIndex, new Map())//putIfAbsent
    const pageOccurrences = occurences.get(pageIndex)!;
    const originalText = getOriginalText(annotation);
    (pageOccurrences.has(originalText)) ? pageOccurrences.set(originalText, (pageOccurrences.get(originalText)!) + 1)
        : pageOccurrences.set(originalText, 1)
    return pageOccurrences.get(originalText)!.toString()
}

function getCommentValue(annotation: any) {
    //The annotation.getContents() returns the original text if it doesn't have a comment but
    //it returns the comment if the comment exists.
    //Else we return the text of the first reply to this annotation, if that exists.
    const replies: any[] = annotation.getReplies()
    return (annotation.getCustomData('trn-annot-preview') !== annotation.getContents()) ? annotation.getContents()
        : (replies.length > 0) ? replies[0].getContents() : "";
}

export function getOriginalText(annotation: any): string {
  return AnnotationFilterHelpers.getOriginalText(annotation)
}

function getStatus(annotation: any) {
    if (annotation.getStatus()===""){
        return "None"
    }
    return annotation.getStatus()
}
