import readXlsxFile, {readSheetNames, Row} from "read-excel-file";
import {
    CHANGE_INTERVALS_TO_MIDPOINTS, DATE_TRANSFORMATION,
    PATIENT_ID_COLUMN_NAME,
    USE_RANDOMIZED_DATE_OFFSET,
    USE_RELATIVE_DAY
} from "./LookupFileGenerator";
import {
    DATE_OFFSET, DateOffsetTransformation,
    getPatientID, IntervalTransformation, isInterval,
    PatientIdTransformation,
    RELATIVE_DATE, RelativeDayTransformation, RETAIN_KEYWORD,
    SimpleLookupTransformation,
    Transformation
} from "../models/DeidData";
import {PATIENT_ID_COLUMN, REPLACEMENT_TEXT_COLUMN} from "../constants";
import {applyDefaultRules} from "./rules/defaultRules";
import {getOriginalText} from "./ReportGenerator";
import {parseDate} from "../models/DateParser";
import {Cell} from "read-excel-file/types";

class LookupParameters {
    dateMode: string;
    patientIdColumnName: string;
    midpointColumns: Set<string>;

    constructor(dateMode: string, patientIdColumnName: string, midpointColumns: Set<string>) {
        this.dateMode = dateMode;
        this.patientIdColumnName = patientIdColumnName;
        this.midpointColumns = midpointColumns;
    }
}

export async function loadTransformsFromLookup (file: File, annotations: any[]) {
    let headerRow: Row = []
    let categoryRow: Row = []
    let pidIndex = -1;
    let patientIDs: string[] = [];
    let categoryToColumnMap = new Map<string, string>();

    let columnToPidToDataMap = new Map<string, Map<string, Transformation>>();
    //Read the hierarchy tabs of the lookup file.
    const columnToHierarchyMap: Map<string, Set<string>[]> = await getColumnToHierarchyMap(file);
    //Read the parameters tab of the lookup file.
    const parameters = await readParameters(file)
    const dateMode = parameters.dateMode;
    const patientIdColumnName = parameters.patientIdColumnName;
    const midpointColumns = parameters.midpointColumns;

    //Read the data tab of the lookup file.
    await readXlsxFile(file, { sheet: 'Data'}).then((rows) => {
        rows.forEach((row) => {
            if (headerRow.length === 0) {
                headerRow = row;
                pidIndex = findPidIndex(row, patientIdColumnName);
                for (let i=0; i < row.length; i+=2) {
                    columnToPidToDataMap.set(getStringValue(row[i]), new Map())
                }
                return;
            }
            if (categoryRow.length === 0) {
                categoryRow = row;
                for (let i=0; i < row.length; i+=2) {
                    categoryToColumnMap.set(getStringValue(row[i]).toLowerCase(), getStringValue(headerRow[i]))
                }
                return;
            }
            const patientID = getStringValue(row[pidIndex]);
            patientIDs.push(patientID);
            for (let i=0; i+1 < row.length; i+=2) {
                if (!row[i]) {
                    continue;
                }
                const columnName = getStringValue(headerRow[i]);
                let outputText = getStringValue(row[i+1])
                //Update the desktop retain keyword with the web app one.
                if (outputText === "%REMOVE_MARK%") {
                    outputText = RETAIN_KEYWORD;
                }
                let transformation: Transformation = new SimpleLookupTransformation(outputText)
                if (columnName === patientIdColumnName) {
                    transformation = new PatientIdTransformation(outputText)
                } else if (isInterval(outputText)) {
                    transformation = getIntervalTransformation(outputText, columnName, columnToHierarchyMap, midpointColumns.has(columnName))
                }
                columnToPidToDataMap.get(columnName)!.set(patientID, transformation);
            }
            //Date transformation
            if (dateMode === DATE_OFFSET) {
                columnToPidToDataMap.get(DATE_TRANSFORMATION)!.set(patientID, new DateOffsetTransformation(parseInt(getStringValue(row[row.length-1]))));
            } else {
                const startDate = parseDate(getStringValue(row[row.length-1]));
                if (startDate) {
                    columnToPidToDataMap.get(DATE_TRANSFORMATION)!.set(patientID, new RelativeDayTransformation(startDate));
                } else {
                    console.log('Not a valid date: ' + getStringValue(row[row.length-1]));
                }
            }
        })
    }).catch((err) => {
        console.log(err);
        throw err;
    })

    applyLookupsToAnnotations(annotations, categoryToColumnMap, columnToPidToDataMap, patientIDs);
}

function applyLookupsToAnnotations(annotations: any[], categoryToColumnMap: Map<string, string>, columnToPidToDataMap: Map<string, Map<string, Transformation>>, patientIDs: string[]) {
    //First associate annotations with patient IDs.
    applyDefaultRules(annotations);
    //Then load replacement text based on the lookup file.
    annotations.forEach(annotation => {
        const category: string = annotation.getCustomData('trn-redaction-type').toLowerCase();
        const column: string|undefined = categoryToColumnMap.get(category);
        const documentPID: string = annotation.getCustomData(PATIENT_ID_COLUMN);
        const datasetPID: string | undefined = getPatientID(documentPID, patientIDs);
        if (column && datasetPID) {
            const replacementText = columnToPidToDataMap.get(column)?.get(datasetPID)?.getOutputText(getOriginalText(annotation));
            if (replacementText) {
                annotation.setCustomData(REPLACEMENT_TEXT_COLUMN, replacementText)
            }
        }
    })
}

function findPidIndex(headerRow: Row, patientIdColumnName: string): number {
    for (let i=0; i < headerRow.length; i++) {
        if (getStringValue(headerRow[i]).toLowerCase() === patientIdColumnName.toLowerCase()) {
            return i;
        }
    }

    headerRow.forEach((cell, index) => {
        if (getStringValue(cell).toLowerCase() === patientIdColumnName.toLowerCase()) {
            return index;
        }
    })
    throw new Error('This column was listed as the PID but its not in the data' + patientIdColumnName);
}

async function getColumnToHierarchyMap(file: File): Promise<Map<string, Set<string>[]>> {
    try {
        const sheetNames = await readSheetNames(file);
        const columnToHierarchyMap: Map<string, Set<string>[]> = new Map();

        for (const sheetName of sheetNames) {
            if (sheetName.endsWith("-hierarchy")) {
                const column = sheetName.split("-")[0];
                const hierarchySets: Set<string>[] = [];
                const rows = await readXlsxFile(file, { sheet: sheetName });

                for (let i = 0; i < rows[0].length; i++) {
                    hierarchySets.push(new Set<string>());
                }

                rows.forEach((row) => {
                    row.forEach((cell, index) => {
                        hierarchySets[index].add(getStringValue(cell));
                    });
                });

                columnToHierarchyMap.set(column, hierarchySets);
            }
        }

        return columnToHierarchyMap;
    } catch (error) {
        console.error("An error occurred while getting the column to hierarchy map:", error);
        throw error;
    }
}

function getIntervalTransformation(outputText:string, column: string, columnToHierarchyMap: Map<string, Set<string>[]>, useMidpoint: boolean) {
    if (!columnToHierarchyMap.has(column)) {
        return new SimpleLookupTransformation(outputText);
    }

    const hierarchySets: Set<string>[] = columnToHierarchyMap.get(column)!;
    let intervalOptionStrings: Set<string> = new Set();
    for (let i=0; i < hierarchySets.length; i++) {
        if (hierarchySets[i].has(outputText)) {
            intervalOptionStrings = hierarchySets[i];
            break;
        }
    }

    if (intervalOptionStrings.size === 0) {
        return new SimpleLookupTransformation(outputText);
    }

    return new IntervalTransformation(outputText, intervalOptionStrings, useMidpoint);
}

function getStringValue(cell: Cell) {
    return cell?.toString() ?? "";
}

async function readParameters(file: File): Promise<LookupParameters> {
    let dateMode = '';
    let patientIdColumnName = '';
    let midpointColumns = new Set<string>();
    return readXlsxFile(file, { sheet: 'Parameters' }).then((rows) => {
        let foundHeaderRow = false;
        rows.forEach(row => {
            if (!foundHeaderRow) {
                foundHeaderRow = true;
                return;
            }
            if (row.length < 3) {
                return;
            }

            const type = getStringValue(row[0]);
            const param = getStringValue(row[1]);
            const value = getStringValue(row[2]);
            switch (param) {
                case USE_RELATIVE_DAY: {
                    if (value === 'Y') {
                        dateMode = RELATIVE_DATE;
                    }
                    break;
                }
                case RELATIVE_DATE: {
                    //Skipping this one because I don't think we ever use anything besides 'Day'.
                    break;
                }
                case USE_RANDOMIZED_DATE_OFFSET: {
                    if (value === 'Y') {
                        dateMode = DATE_OFFSET;
                    }
                    break;
                }
                case PATIENT_ID_COLUMN_NAME: {
                    patientIdColumnName = value;
                    break;
                }
                default : {
                    if (type === CHANGE_INTERVALS_TO_MIDPOINTS && value === 'Y') {
                        midpointColumns.add(value);
                    }
                }
            }
        })
        return new LookupParameters(dateMode, patientIdColumnName, midpointColumns);
    }).catch((err) => {
        console.log('Error reading parameters tab');
        console.log(err);
        throw err;
    })
}