import { MenuItem } from "@mui/material";
import { Project } from "../../models/Project";
import { CustomFieldValue } from "../../models/CustomFieldValue";
import { Task } from "../../models/Task";
import { TaskList } from "../../models/TaskList";
import { TaskTimeTrackSummary } from "../../models/TaskTimeTrackSummary";
import { getApiCustomFieldsValues } from "../../services/custom.fields";
import { getApiTasksByIdWorklogSummary } from "../../services/task";
import {utils, WorkSheet, writeFileXLSX} from "xlsx";
import { getApiUsers } from "../../services/user";
import { SubTask } from "../../models/SubTask";

export function ExportProjectComponent(p: {project: Project, totalPages: number, scopePages: number,
    onMouseEventHandler: any, onLeaveMouseEventHandler: any, setAnchorEl: any}) {

    const getSheetDataForCustomField = (currentTaskCustomFields: CustomFieldValue[], customFieldId: number) => {
        for (const field of currentTaskCustomFields) {
            if (field.customFieldId === customFieldId)
                return field.valueString || field.valueNumber || field.valueDate || "N/A";
        }
        return "N/A";
    }

    const formatDate = (date: string | null | undefined) => {
        if (date) return date.substring(0, 10);
        return "N/A";
    }

    const formatTime = (minutes: number | null | undefined) => {
        if (minutes) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours}h ${remainingMinutes}m`;
        }
        return `${0}h ${0}m`;
    }

    const numberStringsToNumberType = (worksheet: WorkSheet) => {
        Object.keys(worksheet).forEach((cell) => {
            const cellValue = worksheet[cell].v;
            if (cellValue && !isNaN(cellValue)) {
                worksheet[cell].t = 'n';
            }
        });
    }

    const getNamesOfAssignedUsers = async (assignedUserId: string | null | undefined): Promise<string> => {
        const tenantUsers = await getApiUsers({includeDeleted: false});

        if (assignedUserId && p.project.users) {
            const userIdArray: string[] = assignedUserId.split(",");
            const userNameArray: string[] = [];

            for (const userId of userIdArray) {
                if (userId) {
                    for (const user of tenantUsers) {
                        if (user.id === Number(userId)) {
                            userNameArray.push(user.name);
                        }
                    }
                }
            }
            return userNameArray.join(", ");
        }
        return "unassigned";
    }

    const createSheetRowForExcel = async (taskList: TaskList, task: Task | SubTask, parentTaskName: string | null,
        activeCustomFieldData: CustomFieldValue[]) => {

        const namesOfAssignedUsers = await getNamesOfAssignedUsers(task.assignedUserId);
        const taskTimeTrackSummary: TaskTimeTrackSummary = await getApiTasksByIdWorklogSummary(task.id);
        const currentTaskCustomFields: CustomFieldValue[] = activeCustomFieldData.filter((customField) => customField.taskId === task.id);

        const sheetRow = {
                          "Task list name": taskList.name || "N/A",
                          "Task name": task.name || "N/A",
                          "Is subtask": parentTaskName ? "Y" : "N",
                          "Parent task name": parentTaskName || "N/A",
                          "Task description": task.description || "N/A",
                          "Assignees": namesOfAssignedUsers,
                          "Start date": formatDate(task.startDate),
                          "Due date": formatDate(task.dueDate),
                          "Status": task.status || "N/A",
                          "Date completed": formatDate(task.closeDate),
                          "Time tracked": formatTime(taskTimeTrackSummary.minutes),
                          "Number of pages": getSheetDataForCustomField(currentTaskCustomFields, 2),
                          "Pages in scope": getSheetDataForCustomField(currentTaskCustomFields, 3),
                          "Batch": getSheetDataForCustomField(currentTaskCustomFields, 4),
                          "Type of report": getSheetDataForCustomField(currentTaskCustomFields, 5),
                          "Redaction marks": getSheetDataForCustomField(currentTaskCustomFields, 6),
                          "Indentifiers": getSheetDataForCustomField(currentTaskCustomFields, 7)
                         }

        return sheetRow;
    }

    const createProjectDetailsArrayForExcel = (project: Project): Array<Array<string>> => {

        const projectDetailsArray = [];
        projectDetailsArray.push(["Name", project.name]);
        projectDetailsArray.push(["Status", project.projectStatusDates?.status || "N/A"]);
        projectDetailsArray.push(["Start date", formatDate(project.projectStatusDates?.startDate)]);
        projectDetailsArray.push(["Due date", formatDate(project.projectStatusDates?.dueDate)]);
        projectDetailsArray.push(["Study ID", project.studyId || "N/A"]);
        projectDetailsArray.push(["Submission type", project.submissionType!.name || "N/A"]);
        projectDetailsArray.push(["Total number of pages", p.totalPages > 0 ? p.totalPages : "N/A"]);
        projectDetailsArray.push(["Total in scope pages", p.scopePages > 0 ? p.scopePages : "N/A"]);
        projectDetailsArray.push(["Total out of scope pages",
            p.totalPages > 0 && p.scopePages > 0 ? p.totalPages - p.scopePages : "N/A"]);

        if (project.projectDetails !== null && project.projectDetails !== undefined) {
            const keys = Object.keys(project.projectDetails);
            const values = Object.values(project.projectDetails);
            for (let i = 0; i < keys.length; i++) {
                projectDetailsArray.push([keys[i], values[i]]);
            }
        }
        return projectDetailsArray;
    }

    const createTaskListingArrayForExcel = async (project: Project): Promise<Array<Object>> => {

        const taskListingArray = [];
        const activeCustomFieldData = await getApiCustomFieldsValues();

        for (const taskList of project.taskLists!) {
            for (const task of taskList.tasks!) {
                const sheetRow = await createSheetRowForExcel(taskList, task, null, activeCustomFieldData);
                taskListingArray.push(sheetRow);

                for (const subtask of task.subtasks!) {
                    const sheetRow = await createSheetRowForExcel(taskList, subtask, task.name, activeCustomFieldData);
                    taskListingArray.push(sheetRow);
                }
            }
        }
        return taskListingArray;
    }

    const exportProjectAsExcelFile = async () => {

        const projectDetailsArray = createProjectDetailsArrayForExcel(p.project);
        const projectDetailsSheet = utils.aoa_to_sheet(projectDetailsArray);
        numberStringsToNumberType(projectDetailsSheet);

        const taskListingArray = await createTaskListingArrayForExcel(p.project);
        const taskListingSheet = utils.json_to_sheet(taskListingArray);
        numberStringsToNumberType(taskListingSheet);

        const projectWorkbook = utils.book_new();
        utils.book_append_sheet(projectWorkbook, projectDetailsSheet, 'project details');
        utils.book_append_sheet(projectWorkbook, taskListingSheet, 'task listing details');
        writeFileXLSX(projectWorkbook, `${p.project.name}.xlsx`);
    };


    return (
        <MenuItem
            onMouseEnter={p.onMouseEventHandler}
            onMouseLeave={p.onLeaveMouseEventHandler}
            onClick={() => {
                p.setAnchorEl(null);
                exportProjectAsExcelFile();
            }}>
            Export project as excel file
        </MenuItem>
    )
}