import { SubTask } from "../../models/SubTask";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { userSettingsSelector } from "../../redux/userSettings";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import assign_icon from "../../provisional_icons/assign-icon.png";
import baseline_event_available from "../../provisional_icons/baseline-event_available.png";
import baseline_event_due_date from "../../provisional_icons/baseline-event_due_date.png";
import { UsersSelect } from "../users-select";
import { filteredUsersSelector, usersSelector } from "../../redux/users";
import { postApiTasksAssign, putApiTasksById, putApiTasksStatusById } from "../../services/task";
import moment from "moment-timezone";
import { CustomDatePicker } from "../custom-date-picker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { statusList, StatusType } from "../../constants";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { StatusMenuComponent } from "../status-menu-component";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { showSnackbar } from "../../redux/snackbar";
import {
    actionBarProjectIdSelector,
    selectedSubtasksSelector,
    selectSubtasks, selectTasks, setIsTemplate,
    setProjectId, setProjectName
} from "../../redux/action-bar";
import Checkbox from "@mui/material/Checkbox";
import { projectSelector } from "../../redux/projects";
import { ProjectCustomFields } from "../../models/ProjectCustomFields";
import { CustomFieldKeyTypes, TaskListCustomFieldInput } from "./task";
import { cfvByTaskListSelector, customFieldsSelector } from "../../redux/custom-fields";
import { convertDateTimeFromUTCToTimezone, convertMinutesToHours2 } from "../../services/utils";
import { TaskTimeTrackSummary } from "../../models/TaskTimeTrackSummary";
import { taskTimeTrackingSelector } from "../../redux/tasksTimeTracking";
import { useAuthService } from "../../contexts/auth-context";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import {LinkFilesToTaskModalGivenTask} from "../../pages/modals/modal-content/link-files-to-task-modal-given-task";
import {ShowAvailableTaskFilesModal} from "../../pages/modals/modal-content/show-available-task-files-modal";
import {useCustomModal} from "../../pages/modals/custom-message-modal";
import {DragIndicator as DragIndicatorIcon} from "@mui/icons-material";

export function ProjectSubtask(p: { subtask: SubTask, projectName: string, projectId: number, taskListName: string,
    last: boolean, isScroll: boolean, parentId: number, isTemplate: boolean,
    saveTaskCustomField: (taskId: number, customFieldId: number, value: string) => void,
    refreshList: () => void, projectCustomFields: ProjectCustomFields | undefined,
    fetchTask: (isUpdate: boolean, taskId: number) => void,
    onDragStartEvent: (event: any, sortId: any, id: any) => void,
    onDragOverEvent: (event: any) => void,
    onDropEvent:(event: any, sortId: number, id: number, parent: number) => void
}) {

    const auth = useAuthService();
    const { showModal } = useCustomModal();
    const theme = useTheme();
    const nav = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const taskRef = useRef(null);
    const scrollBorderRef = useRef(null);
    const actionBarProjectId = useAppSelector((state) => actionBarProjectIdSelector(state));
    const selectedSubtasks: SubTask[] = useAppSelector((state) => selectedSubtasksSelector(state));
    const project = useAppSelector((state) => projectSelector(state, p.projectId));
    const userSettings = useAppSelector((state) => userSettingsSelector(state));
    const users = useAppSelector((state) =>
        p.isTemplate || (project !== undefined && project.organizationShare) ?
            usersSelector(state, true) : filteredUsersSelector(state, project!.users?.map(u => u.id) || []));
    const customFields = useAppSelector((state) => customFieldsSelector(state));
    const customFieldsValues = useAppSelector((state) =>
        cfvByTaskListSelector(state, p.subtask.taskListId)).customFieldsValues;
    const taskTimeTracking: TaskTimeTrackSummary | undefined =
        useAppSelector((state) => taskTimeTrackingSelector(state, p.subtask.id))
    const isSubtaskSelected = selectedSubtasks.map(sb => sb.id).includes(p.subtask.id);
    const [bgColor, setBgColor] = useState("");

    useEffect(() => {
        if (location.state && (location.state as { taskId: number}).taskId === p.subtask.id && taskRef.current !== null) {
            if (location.pathname !== "/app/user/workflow/projects") {
                scrollToTask();
            } else {
                setTimeout(() => scrollToTask(), 1000);
            }
        }
    }, []);

    useEffect(() => {
        if (p.isScroll && scrollBorderRef.current && taskRef.current) {
            (scrollBorderRef.current as HTMLElement).style.height =
                `${(taskRef.current as HTMLElement).getBoundingClientRect().height}px`;
        }
    }, [p.isScroll])

    const scrollToTask = () => {
        (taskRef.current! as HTMLElement).scrollIntoView({behavior: "smooth", block: "center"});
        setBgColor("#61dafb");
        setTimeout(() => {
            setBgColor("");
            location.state = null;
        }, 500);
    }

    const handleChange = () => {
        if (p.projectId !== actionBarProjectId) {
            dispatch(setProjectId(p.projectId));
            dispatch(setProjectName(p.projectName));
            dispatch(setIsTemplate(p.isTemplate));
            dispatch(selectTasks([]));
        }
        const subtasks = p.projectId !== actionBarProjectId ? [] : [ ...selectedSubtasks ];
        if (isSubtaskSelected) {
            const index = subtasks.map(t => t.id).indexOf(p.subtask.id);
            subtasks.splice(index, 1);
        } else {
            subtasks?.push(p.subtask);
        }
        dispatch(selectSubtasks(subtasks));
    };

    const assignUserOnSubtask = (userIds: number | number[] | undefined) => {
        dispatch(showProgressLine());
        postApiTasksAssign({
            userIds: Array.isArray(userIds) ?
                userIds.join(",") : userIds?.toString()!, taskId: p.subtask.id
        })
            .then(p.refreshList)
            .then(() => dispatch(hideProgressLine()))
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error assigning user on task!", type: "error" }));
            });
    }

    const setDate = (value: Date | null, type: "start" | "due") => {
        dispatch(showProgressLine());
        const body = type === "start" ?
            {
                ...p.subtask,
                startDate: value ? moment(value).format("YYYY-MM-DD 00:00:00") : null
            }
            :
            {
                ...p.subtask,
                dueDate: value ? moment(value).format("YYYY-MM-DD 00:00:00") : null
            }
        putApiTasksById(p.subtask.id, body)
            .then(p.refreshList)
            .then(() => dispatch(hideProgressLine()))
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error setting date!", type: "error" }));
            });
    }

    const setStatus = (status: StatusType) => {
        dispatch(showProgressLine());
        putApiTasksStatusById(p.subtask.id, { status })
            .then(p.refreshList)
            .then(() => dispatch(hideProgressLine()))
            .then(() => p.fetchTask(true, p.subtask.id))
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error setting status!", type: "error" }));
            });
    }

    const getCustomFieldValue = (customFieldId: number) => {
        const customFieldValue = customFieldsValues.find(cfv => cfv.customFieldId === customFieldId
            && cfv.taskId === p.subtask.id);
        if (customFieldValue) {
            return customFieldValue.valueString || "";
        }
        return "";
    }

    const filteredUsers = () => users.filter(u => p.subtask.assignedUserId &&
        p.subtask.assignedUserId.split(",").includes(u.id.toString()))

    return <tr key={p.subtask.id}
               onDragStart={(event) => p.onDragStartEvent(event, p.subtask.sortId, p.subtask.id)}
               onDragOver={p.onDragOverEvent}
               onDrop={(event) => p.onDropEvent(event, p.subtask.sortId, p.subtask.id, p.parentId)}
               draggable
               className="single-template-task-row" ref={taskRef}>
        <td width={30} className="sticky-column">
            <DragIndicatorIcon
                style={{ cursor: "grab" }}

            />
        </td>
        <td className="sticky-column">
            <Checkbox
                checked={isSubtaskSelected}
                onClick={handleChange}
                disabled={!auth.hasPMRoleAccess()}
                color="secondary"
                size="small"
                value={selectedSubtasks}
                style={{
                    position: "relative", left: 6,
                    color: !auth.hasPMRoleAccess() ? "#e6e7e8" : (isSubtaskSelected ? theme.palette.secondary.main : "gray"),
                    marginLeft: -15
                }}
            />
        </td>
        <td className={`single-template-task-name sticky-column-2 ${p.last && "last-row"} 
        ${isSubtaskSelected ? "selected-row-first" : ""}`}
            style={{ borderBottom: p.last ? "1px solid var(--lightGrayishBlue2)" : "",
                display: "flex", justifyContent: "space-between", alignItems: "center", minHeight: 48 }}>
            <div style={{ display: "flex", color: "#223250", cursor: "pointer", alignItems: "center", flexGrow: 1, marginRight: 16 }}
                 onClick={() => nav(`/app/user/workflow/task-details/${p.parentId}/${p.subtask.id}`, { state: { source: location.pathname } })}>
                <SubdirectoryArrowRightIcon color="disabled" sx={{ margin: "0 16px", color: "#e6e7e8" }} />
                <div style={{ backgroundColor: bgColor, transition: "background-color 0.5s ease" }}>
                    {p.subtask.name}
                </div>
            </div>
            {p.isScroll && <div ref={scrollBorderRef} style={{ width: 10, backgroundImage: "linear-gradient(to right, rgba(0,0,0,.1), rgba(255,255,255,0.5))",
                position: "absolute", height: 51, right: 0, top: 0 }} />}
        </td>
        <td className={`${p.last && "last-row"} ${isSubtaskSelected ? "selected-row-middle" : ""}`}>
            {p.isTemplate || (!auth.hasPMRoleAccess() && !p.subtask.assignedUserId) ?
                <img alt="assign" className="single-template-task-icon" src={assign_icon}/>
                :
                <UsersSelect allUsers={users} selectedUsers={filteredUsers()} isTaskList={true}
                             taskRef={taskRef.current} multiple={false} onChange={assignUserOnSubtask} showAvatar={true} />
            }
        </td>
        <td className={`${p.last && "last-row"} ${isSubtaskSelected ? "selected-row-middle" : ""}`}>
            {p.isTemplate || (!auth.hasPMRoleAccess() && !p.subtask.startDate) ?
                <img alt="available" className="single-template-task-icon" src={baseline_event_available} />
                :
                auth.hasPMRoleAccess() ?
                    <CustomDatePicker value={p.subtask.startDate ? new Date(p.subtask.startDate) : null}
                                      setValue={(date) => setDate(date, "start")} name="START DATE" shortView={true}
                                      icon={<CalendarTodayIcon style={{ color: "#939598" }} />} />
                    :
                    <span style={{ fontSize: 13, fontWeight: 600, letterSpacing: 0.4 }}>
                        {moment(p.subtask.startDate).format("MMMM DD")}
                    </span>
            }
        </td>
        <td className={`${p.last && "last-row"} ${isSubtaskSelected ? "selected-row-middle" : ""}`}>
            {p.isTemplate || (!auth.hasPMRoleAccess() && !p.subtask.dueDate) ?
                <img alt="due date" className="single-template-task-icon" src={baseline_event_due_date} />
                :
                auth.hasPMRoleAccess() ?
                    <CustomDatePicker value={p.subtask.dueDate ? new Date(p.subtask.dueDate) : null}
                                      minDate={p.subtask.startDate ? new Date(p.subtask.startDate) : undefined}
                                      setValue={(date) => setDate(date, "due")} name="DUE DATE" shortView={true}
                                      icon={<EventAvailableIcon style={{ color: "#939598" }} />} />
                    :
                    <span style={{ fontSize: 13, fontWeight: 600, letterSpacing: 0.4 }}>
                        {moment(p.subtask.dueDate).format("MMMM DD")}
                    </span>
            }
        </td>
        <td className={`${p.last && "last-row"} ${isSubtaskSelected ? "selected-row-middle" : ""}`} width={100} align="center">
            {p.isTemplate ?
                <span className="single-template-task-status">NOT STARTED</span>
                :
                auth.hasPMRoleAccess() || (auth.hasUserRoleAccess() && p.subtask.assignedUserId &&
                    p.subtask.assignedUserId.split(",").includes(auth.loginInfo?.tenant?.user?.id?.toString() || "")) ?
                    <StatusMenuComponent status={p.subtask.status as StatusType} onUpdate={setStatus} projectStatus={false} />
                    :
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center",
                        letterSpacing: 0.1, color: "white", fontSize: 11, fontWeight: 600,
                        backgroundColor: statusList[p.subtask.status as StatusType].color,
                        width: 86, height: 30, whiteSpace: "nowrap" }}>
                        {statusList[p.subtask.status as StatusType].name}
                    </div>
            }
        </td>
        <td className={`${p.last && "last-row"} ${isSubtaskSelected ? "selected-row-middle" : ""}`}>
            <span style={{ fontSize: 13, letterSpacing: 1.5, color: "#58595B" }}>
                {p.isTemplate ? userSettings.regional.dateFormat : p.subtask.closeDate ?
                    convertDateTimeFromUTCToTimezone(p.subtask.closeDate, userSettings.regional.timezone,
                        userSettings.regional.dateFormat) : '-'}
            </span>
        </td>
        <td className={`${p.last && "last-row"} ${isSubtaskSelected ? "selected-row-middle" : ""}`}>
            <span style={{ fontSize: 13, color: "#58595B", letterSpacing: 1.5 }}>
                {taskTimeTracking ? convertMinutesToHours2(taskTimeTracking.minutes) : '00:00'}
            </span>
        </td>
        {( p.subtask.files && p.subtask.files?.length!==0 ) ?
            <Button className={'task-files-button'} style={{backgroundColor: "inherit", textDecoration: 'underline', color: "blue"}} sx={{fontSize: 1}} onClick={() => {showModal(() => ShowAvailableTaskFilesModal({projectId: p.projectId,taskId: p.subtask.id, files: p.subtask.files!, refreshProject: p.refreshList}), {})}} >FILES AVAILABLE</Button>
            : (auth.hasFMRoleAccess()) ?
            <Button className={'task-files-button'} style={{backgroundColor: "inherit", textDecoration: 'underline', color: "blue"}} sx={{fontSize: 1}} onClick={() => {showModal(() => LinkFilesToTaskModalGivenTask({taskId: p.subtask.id, refreshProject: p.refreshList}), {})}} >LINK FILES</Button>
            : <p style={{ fontSize: 13, letterSpacing: 1.5 }}>NO FILES</p>
        }
        {customFields
            .filter(cf => p.projectCustomFields && p.projectCustomFields[cf.shortName as CustomFieldKeyTypes])
            .map(customField =>
                <td key={customField.id} className={`${p.last ? "last-row" : ""} ${isSubtaskSelected ? "selected-row-middle" : ""}`}>
                    <TaskListCustomFieldInput customFieldId={customField.id}
                                              taskId={p.subtask.id}
                                              value={getCustomFieldValue(customField.id)}
                                              saveValue={p.saveTaskCustomField} />
                </td>)
        }
        <td className={`${p.last && "last-row"}  ${isSubtaskSelected ? "selected-row-last" : ""}`}></td>
    </tr>
}
