import React, { useEffect, useState } from 'react';
import ProgressBar from '../ProgressBar';

const SearchProgressBar = () => {
    const [pagesSearched, setPagesSearched] = useState(0);
    const [pagesToSearch, setPagesToSearch] = useState(0);
    const [verb, setVerb] = useState("Searched");
    const [units, setUnits] = useState("pages");

    const handlePageSearched = (event: any) => {
        setVerb("Searched")
        setUnits("pages")
        setPagesSearched(event.detail.pagesSearched);
        if (pagesToSearch === 0) {
            setPagesToSearch(event.detail.numPagesToSearch)
        }
    };

    const handleNlpResultsLoaded = (event: any) => {
        setVerb("Loaded")
        setUnits("Results")
        setPagesSearched(event.detail.resultsLoaded);
        if (pagesToSearch === 0) {
            setPagesToSearch(event.detail.numOfResults)
        }
    };

    useEffect(() => {
        window.addEventListener('pageSearched', handlePageSearched);
        window.addEventListener('nlpResultLoaded', handleNlpResultsLoaded);
        return () => {
            window.removeEventListener('pageSearched', handlePageSearched);
            window.addEventListener('nlpResultLoaded', handleNlpResultsLoaded);
        };
    }, []);

    return <ProgressBar itemsCompleted={pagesSearched} totalItems={pagesToSearch} verb={verb} units={units}></ProgressBar>
}

export default SearchProgressBar;