import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import baseline_arrow_back from "../../provisional_icons/baseline-arrow-back.png";
import Button from "@mui/material/Button/Button";
import { TaskLists } from "./task-lists";
import { ProjectTemplateListWizard } from "../../pages/user/wizards/project-template-list-wizard";
import { TaskList } from "../../models/TaskList";
import { useCustomModal } from "../../pages/modals/custom-message-modal";
import { useNavigate } from "react-router";
import "./project.scss"
import {
    actionBarIsVisibleSelector,
    selectSubtasks,
    selectTasks,
    setProjectId,
    setProjectName,
    setShowToolbarAfterDeletion,
    setTaskLists
} from "../../redux/action-bar";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { useAuthService } from "../../contexts/auth-context";
import { areFiltersActive, filterTasks } from "../../services/filter-service";
import { projectFiltersSelector, updateFilters } from "../../redux/project-filters";
import {FormControl, InputLabel, SelectChangeEvent, Stack, Select, MenuItem, Box} from "@mui/material";
import ReactPaginate from "react-paginate";
import {Task} from "../../models/Task";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
const itemsPerPageArray = [5,10,20,100,'Show All'];

const prevPagination = () => {
    return(
        <ChevronLeftOutlinedIcon/>
    )
}
const nextPagination = () => {
    return(
        <ChevronRightOutlinedIcon/>
    )
}
export const TaskListComponent=forwardRef((p: {
    projectId: number, projectName: string, taskLists: TaskList[],
    fetchProject: (key?: string) => void, isTemplate: boolean, shortView: boolean,
    taskListObject?: any,
    changeSelectedTask?:(value: string) => void;
}, ref)=> {

    const auth = useAuthService();
    const dispatch = useAppDispatch();
    const actionBarIsVisible = useAppSelector((state) => actionBarIsVisibleSelector(state));
    const filters = useAppSelector((state) => projectFiltersSelector(state, p.projectId));
    const [areTaskListsEmptyAfterFiltering, setAreTaskListsEmptyAfterFiltering] = useState(false);
    const [selectedTaskList, setSelectedTaskList] = useState<string>('')
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = p.taskLists[0]?.tasks?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil( (p.taskLists[0]?.tasks as Task[]).length / itemsPerPage);
    const taskListsCount = p.taskListObject ? Object.keys(p.taskListObject).length : 0
    useEffect(() => {
        dispatch(setTaskLists(p.taskLists));
        return () => {
            dispatch(selectTasks([]));
            dispatch(selectSubtasks([]));
        }
    }, [p.taskLists.length]);

    useImperativeHandle(ref, ()=>{
        return {
            resetSelectedTaskList: (id: string) => {setSelectedTaskList(id)}
        }
    })


     useEffect(() => {
        const arr: number[] = [];
        p.taskLists.forEach(tl => {
            arr.push(filterTasks(tl.tasks || [], filters, auth.loginInfo?.tenant?.user!).length);
        });
        setAreTaskListsEmptyAfterFiltering(areFiltersActive(filters) && arr.every(length => length === 0));
    }, [filters])

    const { showModal } = useCustomModal();
    const nav = useNavigate();

    const refreshProject = (key?: string) => {
        p.fetchProject(key);
    }

    const createTaskList = () => {
        showModal(ProjectTemplateListWizard,
            { projectId: p.projectId, isTemplate: p.isTemplate, refreshList: refreshProject });
        dispatch(setShowToolbarAfterDeletion(false));
    };

    const clearFiltersOnClick = () => {
        dispatch(updateFilters({
            ...filters, searchTerm: "", groupBy: undefined, showMyTasks: undefined, assignees: [],
            showSubtasks: undefined, filter: { keyword: "", status: { type: undefined, color: undefined }, date: null },
        }));
    }

    const filterTaskList = (event:SelectChangeEvent) => {
        setItemOffset(0)
        setSelectedTaskList(event.target.value as string)
        p?.changeSelectedTask?.(event.target.value as string);
        dispatch(selectTasks([]))
    }

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % (p.taskLists[0]?.tasks as Task[]).length;
        setItemOffset(newOffset);
    }

    const height = p.shortView ? "" : `calc(100vh - 184px${actionBarIsVisible ? " - 60px" : ""})`;
    const paginatedTask = [{...p.taskLists[0], tasks: currentItems}]

    return <div className="single-project-template-box-no-scroll"
        style={{ height, minHeight: 300, margin: p.isTemplate ? 32 : 0 }}>
        {p.isTemplate &&
            <>
                <div className="single-project-template-header">
                    <div className="single-project-template-back">
                        <img alt="back" src={baseline_arrow_back} onClick={() => nav(-1)}
                            className="single-project-temaplate-back-button" />
                        <span className="single-project-template-name">{p.projectName}</span>
                    </div>
                </div>
                <div className="single-project-template-horizontal-line" />
            </>
        }
        <Stack direction="row" justifyContent="space-between" alignItems="center">
        {!p.shortView && auth.hasPMRoleAccess() && <Button onClick={createTaskList} color="secondary" variant="outlined" style={{ marginLeft: 24, marginTop: p.isTemplate ? 0 : 24 }}>
            CREATE TASK LIST
        </Button>}
            <div style={{ marginRight: 24, marginTop: p.isTemplate ? 0 : 24,minWidth: '400px' }} >
                <Stack direction="row" spacing="1em" alignItems="center">
                <FormControl fullWidth size="small">
                    <InputLabel>Select Task List</InputLabel>
                    <Select label="Select Task List" value={selectedTaskList} onChange={filterTaskList}>
                        {p.taskListObject && Object.keys(p.taskListObject).length > 0 && Object.keys(p.taskListObject).reverse().map(key=>{
                            return <MenuItem value={key}>{p.taskListObject[key].name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{width: '190px'}}  size="small">
                    <InputLabel>Per Page</InputLabel>
                    <Select disabled={(p.taskLists[0].tasks as Task[]).length <= itemsPerPageArray[0] ?? true} label="Per Page"  value={itemsPerPage.toString()} onChange={(event:SelectChangeEvent)=>setItemsPerPage(+event.target.value)}>
                        {itemsPerPageArray.map(item=>{
                            return <MenuItem key={item} value={item === 'Show All' ? p.taskLists[0]?.tasks?.length : item}>{item}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                </Stack>
            </div>
        </Stack>

        {!areTaskListsEmptyAfterFiltering ? <div style={{
            height: p.isTemplate ?
                `calc(100vh - 64px - 56px - 32px - 160px${actionBarIsVisible ? " - 60px" : ""})`
                : "auto", paddingBottom: 16, overflowX: "hidden", overflowY: p.isTemplate ? "auto" : "hidden",
            margin: p.shortView ? "24px 0" : "16px 0"
        }}>
            <TaskLists projectName={p.projectName} taskLists={paginatedTask} taskListsCount={taskListsCount} fullList={p.taskLists[0]} projectId={+p.projectId}
                fetchProject={refreshProject} isTemplate={p.isTemplate} shortView={p.shortView} />
            <Box className="pagination">

                <ReactPaginate
                    breakLabel="..."
                    nextLabel={nextPagination()}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={itemsPerPage}
                    pageCount={pageCount}
                    previousLabel={prevPagination()}
                    renderOnZeroPageCount={null}
                    breakClassName="item"
                    breakLinkClassName="item-link"
                    forcePage={itemOffset === 0 ? itemOffset: undefined}
                /></Box>
        </div>
            : <div style={{ margin: 32, display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#F5F6F7" }}>
                <div style={{ fontSize: 17, letterSpacing: 0.49, marginTop: 32 }}>Filter applied. No results for those filters.</div>
                <Button style={{marginTop: 24, marginBottom: 32,   width: 146 }}
                    variant="outlined" color="secondary" onClick={clearFiltersOnClick}>CLEAR FILTERS</Button>
            </div>}
    </div>
})
