import React, {useContext, useEffect, useState} from 'react';
import {FileState} from "../../models/FileState";
import {useCustomModal} from "./custom-message-modal";
import './load-from-risk-modal.scss'
import {getFileNoCacheAndUnzipInMemory} from "../../components/file-management";
import {DeidData, REDACTION_KEYWORD} from "../../models/DeidData";
import {applyDefaultRules} from "../../services/rules/defaultRules";
import {REPLACEMENT_TEXT_COLUMN} from "../../constants";
import {showSnackbar} from "../../redux/snackbar";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination, Box, MenuItem, Button, Autocomplete, TextField, Paper,
} from '@mui/material';
import {Select} from '@mui/material';


import {DataGridPro, GridColDef, GridRowsProp, GridToolbar} from "@mui/x-data-grid-pro";
import {fileCategories} from "../../components/files-component";
import WebViewerContext from "../../contexts/webviewer-context";
import {lookupFileGenerator} from "../../services/LookupFileGenerator";

const LoadTransformsFromRiskModal = (props: { projectFiles: FileState[], annotations: any[], onDone: () => void }) => {
    const { setInstance,instance } = useContext(WebViewerContext);
    const annotationManager = instance.Core.annotationManager;
    const [selectedDEID, setSelectedDEID] = useState<string | null>(null);
    const [patientIdColumn, setPatientIdColumn] = useState<string | undefined>(undefined)
    const [selectedTab, setSelectedTab] = useState<string | null>(null);
    const [categories, setCategories] = useState<string[]>([])
    const [deidData, setDeidData] = useState<DeidData | null>(null)
    const [annotationMap, setAnnotationMap] = useState<Map<string, AnnotationData>>(new Map)
    const [categoryToColumnMap, setCategoryToColumnMap] = useState<Map<string, string | undefined>>(new Map)
    const [midpointCategories, setMidpointCategories] = useState<Set<string>>(new Set())
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [transformToCategory, setTransformToCategory] = useState<boolean>(false);
    const {hideModal} = useCustomModal();
    const abortController = new AbortController();
    const abortSignal = abortController.signal;

    useEffect(() => {
        //assign patient IDs to annotations
        applyDefaultRules(props.annotations);

        //get all unique categories from annotations
        let categorySet = new Set<string>();
        props.annotations.forEach(annotation => {
            const category = annotation.getCustomData('trn-redaction-type');
            if (category) {
                categorySet.add(category);
            }
        });
        const categoryArray = Array.from(categorySet)
        categoryArray.sort()
        setCategories(categoryArray)
    }, [])

    //Load the DEID project when the user selects one.
    //Also reload it if they change the Patient ID column.
    useEffect(() => {
        if (!selectedDEID) {
            return
        }
        setIsLoading(true);
        let projectToLoad = undefined;
        for (const deid of deidProjects) {
            if (selectedDEID === deid.name) {
                projectToLoad = deid
                break
            }
        }

        if (projectToLoad && projectToLoad.url) {
            const newDeidData = new DeidData(patientIdColumn)
            getFileNoCacheAndUnzipInMemory(projectToLoad.url, abortSignal)
                .then(extractedFiles => {
                    return newDeidData.initialize(extractedFiles)
                })
                .then(result => {
                    setDeidData(newDeidData)
                    if (newDeidData.patientIDColumn) {
                        if (newDeidData.patientIDColumn !== patientIdColumn) {
                            setPatientIdColumn(newDeidData.patientIDColumn)
                        }
                        //annotation Map
                        const c2cMap = newDeidData.buildCategoryToColumnMap(categories)
                        setCategoryToColumnMap(c2cMap)
                        setAnnotationMap(newDeidData.buildAnnotationMap(props.annotations, c2cMap))
                    } else {
                        setPatientIdColumn('missing')
                    }
                    setIsLoading(false)
                    setSelectedTab(categories[0])
                })
                .catch(error => {
                    console.log(error)
                    setIsLoading(false)
                    //TODO this snackbar isn't appearing
                    showSnackbar({message: 'Error loading RLS Protect Risk project', type: 'error'})
                })
        } else {
            console.log("Error finding DEID")
            setIsLoading(false)
            showSnackbar({message: "Couldn't find this RLS Protect Risk project", type: 'error'})
        }
    }, [selectedDEID, patientIdColumn])

    //TODO this will reset any user selected patient IDs
    useEffect(() => {
        if (deidData) {
            const midpointColumns: Set<string> = new Set();
            midpointCategories.forEach(category => {
                if (categoryToColumnMap.get(category)) {
                    midpointColumns.add(categoryToColumnMap.get(category)!);
                }
            })
            deidData.buildOutputMap(midpointColumns).then(() => setAnnotationMap(deidData!.updateAnnotationMapReplacementText(annotationMap)))
        }
    }, [midpointCategories]);

    const deidProjects: FileState[] = props.projectFiles.filter((project) =>
        project.name.endsWith('.deid')
    );

    // Filter annotations based on the selected tab
    const filteredAnnotations = selectedTab
        ? props.annotations.filter((annotation) => annotation.getCustomData('trn-redaction-type') === selectedTab)
        : [];

    const handleCancel = () => {
        abortController.abort();
        setIsLoading(false);
        hideModal()
    };

    const handleMappingCategoryToColumn = (category: string, column: string) => {
        const newMap = new Map(categoryToColumnMap)
        newMap.set(category, column)
        setCategoryToColumnMap(newMap)

        //Update all the annotations of this category
        const newMap2 = new Map(annotationMap)
        props.annotations.map(annotation => annotationMap.get(annotation.Id)).filter(data => data?.category === category).forEach(data => {
            data!.column = column
        })
        setAnnotationMap(newMap2)
    }

    const handleMappingPatientIDs = (annotationID: string, patientID: string) => {
        const newMap = new Map(annotationMap)
        newMap.get(annotationID)!.datasetPID = patientID
        setAnnotationMap(newMap)
    }

    const handleSetMidpoint = (category: string) => {
        const midpointCategoryCopy = new Set(midpointCategories);
        if (midpointCategoryCopy.has(category)) {
            midpointCategoryCopy.delete(category);
        } else {
            midpointCategoryCopy.add(category);
        }
        setMidpointCategories(midpointCategoryCopy);
    }

    const tabMissingInfo = (category: string) => {
        const annotations = props.annotations.map(annotation => annotationMap.get(annotation.Id)).filter(data => data?.category === category)
        for (const annotation of annotations) {
            if (annotation?.missingInfo()) {
                return true;
            }
        }
        return false;
    }

    //TODO this is too slow
    const getTabStyling = (category: string) => {
        return `tab-button ${category === selectedTab ? 'active' : ''} ${tabMissingInfo(category) ? 'missing' : ''}`
    }

    const onDone = () => {
        props.annotations.forEach(annotation => {
            const annotationData = annotationMap.get(annotation.Id)!
            let replacementText = deidData?.getReplacementText(annotationData.column, annotationData.datasetPID, annotationData.documentText)
            replacementText = formatReplacementText(annotationData.category, transformToCategory, replacementText);
            if (replacementText) {
                annotation.setCustomData(REPLACEMENT_TEXT_COLUMN, replacementText)
            }
        })
        annotationManager.trigger('annotationChanged', props.annotations, 'replacementChange', {imported: false}) //This trigger will notify the webviewer
        //that there have been changes to the document and hence it will keep track of those changes if the user switched tabs in the webviewer
        props.onDone()
        hideModal()
    }

    return (
        <div className="modal">
            <h2>Load Transforms</h2>

            {/* Dropdown for selecting a project */}
            <div style={{textAlign: 'left', marginBottom: '15px'}}>
                <label style={{display: 'inline-block'}}>Choose RLS Protect Risk project:</label>
                <select
                    style={{display: 'inline-block', marginLeft: '5px'}}
                    onChange={(e) => {
                        setPatientIdColumn(undefined);
                        setSelectedDEID(e.target.value);
                    }}
                    value={selectedDEID || ''}
                >
                    <option value="">Select a project</option>
                    {deidProjects.map((project) => (
                        <option key={project.name} value={project.name}>
                            {project.name}
                        </option>
                    ))}
                </select>
            </div>

            {/*Only show the rest of this once they've selected a risk file*/}
            {selectedDEID !== null && <div>
                {/*Dropdown for selecting the patient ID column */}
                <div>
                    <label>Dataset column with the complete patient ID:</label>
                    <select
                        className={patientIdColumn === 'missing' ? 'missing' : ''}
                        onChange={(e) => setPatientIdColumn(e.target.value)}
                        value={patientIdColumn || ''}
                        title={patientIdColumn === 'missing' ? 'Please select a column from the RLS Protect Risk project that contains patient IDs' : ''}
                    >
                        <option key={'missing'} value={'missing'}>
                            {'missing'}
                        </option>
                        {deidData?.headers.map((column) => (
                            <option key={column} value={column}>
                                {column}
                            </option>
                        ))}
                    </select>
                </div>
                <p>
                    <label>
                        <input
                            type="checkbox"
                            checked={transformToCategory}
                            onChange={() => setTransformToCategory(!transformToCategory)}
                        />
                        Transform to category instead of redacting
                    </label>
                </p>

                {isLoading ?
                    (<LoadingSpinner/>) :
                    (<>
                        {/* Tabs for different annotation categories */}
                        <div className="tabs">
                            {categories.map((category) => (
                                <button
                                    key={category}
                                    onClick={() => setSelectedTab(category)}
                                    className={getTabStyling(category)}
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                        {selectedTab && deidData && filteredAnnotations ?
                            <CategoryTab category={selectedTab} column={categoryToColumnMap.get(selectedTab)}
                                         annotations={filteredAnnotations} deidData={deidData}
                                         annotationMap={annotationMap}
                                         handleMappingCategoryToColumn={handleMappingCategoryToColumn}
                                         handleMappingPatientIDs={handleMappingPatientIDs}
                                         handleSetMidpoint={handleSetMidpoint}
                                         midpointColumns={midpointCategories}
                                         transformToCategory={transformToCategory}></CategoryTab> : <div/>}
                    </>)
                }
            </div>}
            <div className={"button-container"}>
                <Button variant="contained" color="secondary"
                        className={'transform-button'}
                        onClick={() => lookupFileGenerator(selectedDEID!, deidData!, categoryToColumnMap, midpointCategories)}
                        disabled={isLoading || selectedDEID === null}
                >
                    Download Lookup File
                </Button>
                <button className='change-status-btn' onClick={() => handleCancel()}>Cancel</button>
                <button className='change-status-btn success' onClick={() => onDone()}>Done</button>
            </div>
        </div>
    );
};

const CategoryTab = (props: {
    category: string,
    column: string | undefined,
    annotations: any[],
    deidData: DeidData,
    annotationMap: Map<string, AnnotationData>,
    handleMappingCategoryToColumn: (category: string, column: string) => void,
    handleMappingPatientIDs: (annotationId: string, patientID: string) => void,
    handleSetMidpoint: (category: string) => void,
    midpointColumns: Set<string>,
    transformToCategory: boolean
}) => {
    const [onlyShowMissingPIDs, setOnlyShowMissingPIDs] = useState<boolean>(false)

    const missingPIDs = props.annotations.some(annotation => props.annotationMap.get(annotation.Id)?.missingPID())


    return <div className={'category-table-container'}>
        {/* Dropdown for dataset category */}
        <div>
            <label>Dataset category:</label>
            <select className={props.column === undefined ? 'missing' : ''}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string | null>; };
                    }) => props.handleMappingCategoryToColumn(props.category, e.target.value!.toString())}
            >
                <option key={'missing'} value={'missing'} selected={true}>
                    {'missing'}
                </option>
                {!props.deidData ? [] : props.deidData.headers.map((columnName: string) => (
                    <option key={columnName} value={columnName} selected={columnName === props.column}>
                        {columnName}
                    </option>
                ))}
            </select>
            {
                (props.column && props.deidData.intervalColumns.has(props.column!)) && (
                    <p>
                    <label>
                        <input
                            type="checkbox"
                            checked={props.midpointColumns.has(props.category)}
                            onChange={() => props.handleSetMidpoint(props.category)}
                        />
                        Use midpoint instead of intervals
                    </label>
                    </p>
                )
            }
            {!props.column && (
                <p style={{color: 'red'}}>
                    I couldn't load transforms for this category because I don't know what column in the dataset to
                    use.
                    Please choose one of the columns from the dropdown above.
                </p>
            )}
            {missingPIDs && (
                <div>
                    <p style={{color: 'red'}}>
                        In the cells highlighted in red, I’m not sure which patient from the dataset to use. If you want
                        to transform those rows, please select a patient ID from the dropdown.
                    </p>
                    <label>
                        <input
                            type="checkbox"
                            checked={onlyShowMissingPIDs}
                            onChange={() => setOnlyShowMissingPIDs(!onlyShowMissingPIDs)}
                        />
                        Only show rows with missing Patient ID
                    </label>
                </div>
            )
            }
        </div>

        {/* Table for displaying annotation data */}
        <div className="table-container">
            <CategoryTable category={props.category} column={props.column} annotations={props.annotations}
                           deidData={props.deidData} annotationMap={props.annotationMap}
                           handleMappingCategoryToColumn={props.handleMappingCategoryToColumn}
                           handleMappingPatientIDs={props.handleMappingPatientIDs}
                           onlyShowMissingPID={missingPIDs && onlyShowMissingPIDs}
                           transformToCategory={props.transformToCategory}/>
        </div>
    </div>
}

const CategoryTable = (props: {
    category: string,
    column: string | undefined,
    annotations: any[],
    deidData: DeidData,
    annotationMap: Map<string, AnnotationData>,
    handleMappingCategoryToColumn: (category: string, column: string) => void,
    handleMappingPatientIDs: (annotationId: string, patientID: string) => void,
    onlyShowMissingPID: boolean,
    transformToCategory: boolean
}) => {

    const annotationsToDisplay = props.annotations
        .map((annotation) => props.annotationMap.get(annotation.Id)!)
        .filter(annotationData => !props.onlyShowMissingPID || annotationData.missingPID());

    const patientIdOptions: string[] = props.deidData?.patientIDs ?? []

    const CustomPaper = (props: any) => {
        return <Paper elevation={8} style={{border: '5px solid #ccc', marginTop: '4px'}} {...props}/>;
    };

    const columns: GridColDef[] = [
        {
            field: 'col1', headerName: 'Document Text', flex: 2
        },
        {
            field: 'col2', headerName: 'Page No.', flex: 2
        },
        {
            field: 'col3', headerName: 'Patient ID', flex: 2,
        },
        {
            field: 'col4', headerName: 'Dataset Patient ID', flex: 3, renderCell: params => {
                const id = params.row.col4 ?? "missing";
                const backgroundColor = (params.row.col3 && !params.row.col4) ? "#C13826": "white";
                return <Autocomplete
                    id="virtualize-demo"
                    options={patientIdOptions.concat(['missing'])}
                    style={{width: "100%", backgroundColor: backgroundColor}}
                    value={id}
                    disableClearable
                    PaperComponent={CustomPaper}
                    renderInput={(params) => <TextField {...params}/>}
                    renderOption={(props, option) =>
                        <li {...props}>{option}</li>
                    }
                    onChange={(ev, value) => {
                        if (value !== null) {
                            props.handleMappingPatientIDs(params.row.id, value)
                        }
                    }}
                />
            }
        },
        {
            field: 'col5', headerName: 'Replacement Text', flex: 2, renderCell: params => {
                const replacementText = params.row.col5;
                const redact = replacementText === "%REDACT%";
                const retain = replacementText === "%RETAIN%";
                const catTrans = replacementText === formatCategoricalTransform(params.row.category);
                const backgroundColor = (redact || catTrans) ? 'blue' : retain ? 'green' : 'inherit';
                const textColor = (redact || retain || catTrans) ? "white" : "inherit";
                const displayReplacementText = redact || retain ? replacementText!.substring(1, replacementText!.length-1) : replacementText;

                return <div style={{ border: 'none', backgroundColor: backgroundColor, color: textColor, borderRadius: '3px' }}>{displayReplacementText}</div>
            }
        }
    ];

    const getRows = () => {
        let i = 0;
        let rows: GridRowsProp = [];
        annotationsToDisplay.forEach(annotationData => {
            let replacementText: string | undefined = props.deidData?.getReplacementText(
                annotationData.column,
                annotationData.datasetPID,
                annotationData.documentText
            )
            replacementText = formatReplacementText(annotationData.category, props.transformToCategory, replacementText);
            rows = rows.concat({
                id: annotationData.id,
                col1: annotationData.documentText,
                col2: annotationData.pageNum,
                col3: annotationData.documentPID,
                col4: annotationData.datasetPID,
                col5: replacementText,
                category: annotationData.category
            });
        })
        return rows;
    }
    return (
        <Box style={{minHeight: 50, maxHeight: 540, width: '100%'}}>
            <DataGridPro
                sx={{
                    // disable cell selection style
                    '.MuiDataGrid-cell:focus': {
                        outline: 'none'
                    },
                    // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer'
                    }
                }}
                initialState={{
                    pagination: {paginationModel: {pageSize: 10}},
                }}
                rowHeight={30}
                style={{height: "calc(100vh - 460px)"}}
                rows={getRows()}
                columns={columns}
                pagination
                pageSizeOptions={[10, 25, 50, 100]}
                getDetailPanelHeight={() => 'auto'} // Height based on the content.
                disableRowSelectionOnClick={true}
                disableColumnSelector
                disableDensitySelector

                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        csvOptions: {disableToolbarButton: true},
                        printOptions: {disableToolbarButton: true},
                    },
                }}
                {...getRows()}
            />
        </Box>
    )
};


export const LoadingSpinner: React.FC = () => {
    return (
        <div className="loading-spinner">
            <div className="spinner"></div>
        </div>
    );
};

export class AnnotationData {
    id: string;
    documentText: string;
    pageNum: number;
    documentPID: string;
    datasetPID: string | undefined;
    replacementText: string | undefined;
    category: string;
    column:  string | undefined;

    constructor(id: string, documentText: string, pageNum: number, documentPID: string, datasetPID: string | undefined, replacementText: string | undefined, category: string, column: string | undefined) {
        this.id = id;
        this.documentText = documentText;
        this.pageNum = pageNum;
        this.documentPID = documentPID;
        this.datasetPID = datasetPID;
        this.replacementText = replacementText;
        this.category = category
        this.column = column
    }

    missingInfo = () => {
        return this.missingPID() || !this.column
    }

    missingPID = () => {
        return this.documentPID && !this.datasetPID
    }

}

export const formatReplacementText = (category: string|undefined, transformToCategory: boolean, replacementText: string|undefined) => {
    if (transformToCategory && replacementText === REDACTION_KEYWORD && category) {
        return formatCategoricalTransform(category)
    } else {
        return replacementText
    }
}

export const formatCategoricalTransform = (category: string) => {
    category = category.replaceAll('_', ' ');
    return `(${category})`
}

export default LoadTransformsFromRiskModal;

