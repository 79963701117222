import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { MoreActionsComponent } from "../more-actions-component";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import { useEffect, useState } from "react";
import { TaskList } from "../../models/TaskList";
import MenuItem from "@mui/material/MenuItem";
import { Task } from "../../models/Task";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import baseline_close from "../../provisional_icons/baseline-close.png";
import Tooltip from "@mui/material/Tooltip";

export function TaskListSearch(props: {
    taskListData: TaskList[], menuItemOnClick: (value: TaskList | Task) => void, dependencyArray: Array<any>,
    type: "TASK" | "LIST", icon: JSX.Element, anchorEl: HTMLElement | null, setAnchorEl: (value: HTMLElement | null) => void,
    tooltip?: string, bothTaskTypesSelected?: boolean, showInPage?: boolean, projectName: string,
    selectedTaskLists?: TaskList[], selectedTasks?: Task[], open?: boolean
}) {
    const [search, setSearch] = useState("");
    const [dataMenuItems, setDataMenuItems] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const items: JSX.Element[] = [];
        if (props.taskListData) {
            props.taskListData.forEach(taskList => {
                if (props.type === "TASK") {
                    taskList.tasks?.forEach(task => {
                        if (search) {
                            if (!task.name.includes(search)) return;
                        }
                        items.push(<Tooltip title={`${props.projectName} > ${taskList.name}`}>
                            <MenuItem
                                key={task.id}
                                style={props.selectedTasks?.some(t => t.id === task.id) ? {
                                        marginLeft: 10,
                                        marginRight: 10,
                                        backgroundColor: "#EDF5F8"
                                    } :
                                    {marginLeft: 10, marginRight: 10}}
                                onClick={() => props.menuItemOnClick(task)}>
                                <span>{task.name}</span>
                            </MenuItem>
                        </Tooltip>);
                    })
                } else {
                    if (search) {
                        if (!taskList.name.includes(search)) return;
                    }
                    items.push(<MenuItem
                        key={taskList.id}
                        style={props.selectedTaskLists?.some(tl => tl.id === taskList.id) ? {
                                marginLeft: 10,
                                marginRight: 10,
                                backgroundColor: "#EDF5F8"
                            } :
                            {marginLeft: 10, marginRight: 10}}
                        onClick={() => props.menuItemOnClick(taskList)}>
                        <span>{taskList.name}</span>
                    </MenuItem>);
                }
            });
        }
        if (!items.length) {
            items.push(<MenuItem
                key="No results"
                style={{ marginLeft: 10, marginRight: 10, display: "flex", justifyContent: "center" }}
                onClick={() => { }}>
                <span style={{ fontSize: 15, color: "#939598", fontStyle: "italic" }}>No results</span>
            </MenuItem>)
        }
        setDataMenuItems(items);
    }, [search, props.dependencyArray, props.type, props.selectedTasks, props.selectedTaskLists])

    const menuItems = [
        <FormControl variant="standard">
            <Input
                id="input-with-icon-adornment"
                disableUnderline
                placeholder={`Search for ${props.type.toLowerCase().charAt(0).toUpperCase() + props.type.slice(1).toLowerCase()}s`}
                value={search}
                style={{ marginLeft: 10, marginTop: 10, width: props.showInPage ? 356 : 200 }}
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon color="secondary" />
                    </InputAdornment>
                }
                endAdornment={
                    search && <InputAdornment position="end" style={{ marginRight: props.showInPage ? 25 : 10 }} >
                        <IconButton onClick={() => setSearch("")}>
                            <img alt="close" src={baseline_close} style={{ width: 20, height: 20 }} />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>,
        <div style={{ border: "0.5px solid #E6E7E8", marginBottom: 8, marginTop: 10 }}></div>
    ].concat(dataMenuItems);

    return !props.showInPage ? <MoreActionsComponent
        key={props.type}
        id={props.type}
        menuItems={menuItems}
        icon={props.icon}
        anchorEl={props.anchorEl}
        setAnchorEl={!props.bothTaskTypesSelected ? props.setAnchorEl : () => { }}
        menuHorizontalPosition="center"
        tooltip={props.tooltip}
        open={props.open}
    /> : <Box boxShadow={1} style={{ maxHeight: 248, marginRight: 15, overflowY: "auto", overflowX: "hidden" }}>
        {menuItems.map(el => el)}
        <div style={{ marginBottom: 10 }} />
    </Box>
}
