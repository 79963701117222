import './single-project-template.scss';
import React, {useEffect, useRef, useState} from "react";
import { useEffectAsync } from "../../hooks/async-effect";
import { getApiTemplatesById } from "../../services/template";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { TaskListComponent } from "../../components/project/task-list-component";
import { setIsTemplate, setProjectId } from "../../redux/action-bar";
import { loadTemplate, projectTemplateSelector } from "../../redux/projectTemplates";
import { showSnackbar } from "../../redux/snackbar";
import { useParams } from 'react-router-dom';
import {TaskList} from "../../models/TaskList";

export function SingleProjectTemplate() {

    const id = parseInt(window.location.href.split('/').pop()!);
    const dispatch = useAppDispatch();
    const ref = useRef<any>(null);
    const [taskListObject, setTaskListObject] = useState<Record<string, TaskList>>({});
    const [selectedTaskLists, setSelectedTaskList] = useState<any>({})
    const [selectedKey,setSelectedKey] = useState('')
    const singleTemplate = useAppSelector((state) => projectTemplateSelector(state, id));
    const { taskListId } = useParams();

    useEffectAsync(async () => {
        fetchTemplate();
    }, [id])

    useEffect(() => {
        const listObject: any = {}
        singleTemplate?.taskLists?.filter(tl => taskListId ? tl.id === +taskListId : true).map(tl => listObject[`${tl.id}`] = tl);
        setTaskListObject(listObject)
        const keys = Object.keys(listObject);
        let index = selectedKey !== '' ? keys.indexOf(selectedKey) : keys.length - 1;
        keys.length > 0 && setSelectedTaskList(listObject[keys[index]])
        if(ref?.current){
            ref?.current.resetSelectedTaskList(keys[index])
        }
    }, [singleTemplate]);

    const fetchTemplate = () => {
        dispatch(showProgressLine());
        getApiTemplatesById(id)
            .then(template => {
                dispatch(loadTemplate(template));
                dispatch(hideProgressLine());
                dispatch(setProjectId(id));
                dispatch(setIsTemplate(true));
            })
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error loading template!", type: "error" }));
            })
    }

    return <div className="single-project-template-wrapper">
        {(singleTemplate && Object.keys(selectedTaskLists).length > 0) &&
            <TaskListComponent ref={ref} projectId={singleTemplate.id} projectName={singleTemplate.name} shortView={false}
                               taskLists={[selectedTaskLists]} fetchProject={fetchTemplate} isTemplate={true} taskListObject={taskListObject} changeSelectedTask={(id: string)=>setSelectedTaskList(taskListObject[id])} />
        }
    </div >
}
