import {useNavigate} from "react-router-dom";
import "./pattern.scss";
import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {ArrowBack, Delete} from "@mui/icons-material";
import { Button } from "@mui/material";
import {PatternSet} from "../../models/PatternSet";
import {Pattern} from "../../models/Pattern"
import {useAppDispatch, useAppSelector} from "../../hooks/redux-hook";
import {
    addPatternsToSet,
    loadPatternSet,
    patternSetSelector, updateModifiedTimeOnPatternSet,
    updatePattern
} from "../../redux/patternSets";
import {showSnackbar} from "../../redux/snackbar";
import {
    createNewVersionOfPatternSet,
    putApiPatternSet,
    putApiPatternDetailsById, putApiPatternTextFileById, getApiPatternsBySetId
} from "../../services/patternSets";
import {useAuthService} from "../../contexts/auth-context";
import {getIdAndUpdatedTime, getPatternFormForUpload} from "./single-pattern-set";
import {getFileNoCache} from "../../components/file-management";
import {CATEGORY_SPLITTER, colorPalette} from "../../constants";
import {useCustomModal} from "../modals/custom-message-modal";
import {hideProgressLine, showProgressLine} from "../../redux/progress-line";
import {LoadingMessage} from "./wizards/loading-message";
import {envDir} from "../../services/config";

//There are two different components here because the user can access this page either from the master patterns library
//or from project details.
export function SinglePatternFromMaster() {
    return SinglePattern({fromProject: false})
}

export function SinglePatternFromProject() {
    return SinglePattern({fromProject: true})
}

export function SinglePattern(props : {fromProject: boolean}) {
    const { showModal } = useCustomModal();
    const { hideModal } = useCustomModal();
    let urlArray = window.location.href.split('/');
    const id = urlArray.pop()!
    const masterId = urlArray.pop()!
    let failedToLoad = false;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const mp : PatternSet | undefined = useAppSelector((state) => patternSetSelector(state, +masterId ));
    const selectedPattern : Pattern | undefined = mp?.patterns?.filter(pattern => pattern.id === +id).pop()
    const startPattern : Pattern = selectedPattern? selectedPattern : {id: -1, name: "", category: "", description: "", color: "", raw_pattern_location: "", master_id: -1} as Pattern
    if (!mp || !selectedPattern) {
        failedToLoad = true
    }

    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState("tab1");

    useEffect(() => {
        dispatch(showProgressLine());
        getApiPatternsBySetId(parseInt(masterId))
            .then(patterns => {
                dispatch(addPatternsToSet(patterns))
            })
            .catch((reason) => {
                dispatch(showSnackbar({ message: "Error loading patterns!", type: "error" }));
                console.log(reason)
            })
        dispatch(hideProgressLine());
        setIsLoading(false);
    }, [])
    //  Functions to handle Tab Switching
    const handleTab1 = () => {
        setActiveTab("tab1");
    };
    const handleTab2 = () => {
        setActiveTab("tab2");
    };

    const newVersionInProgress: boolean = mp !== undefined && mp.name !== undefined && (sessionStorage.getItem('editing-master-pattern') === mp.name)
    const [editEnabled, setEditEnabled] = useState(newVersionInProgress || props.fromProject || mp?.projectID !== null)
    const auth = useAuthService();

    if (isLoading) return <div>Loading</div>

    if (failedToLoad) {
        return <div>
            <ArrowBack className='arrow-back' onClick={() => goBack()} />
            <h1>Error loading patterns!</h1>
        </div>
    } else if (!auth.hasPMRoleAccess() && !props.fromProject) {
        return <p>Your user account does not have permission to access this path. Please contact your System Administrator or RLS Customer Support for assistance.</p>
    }

    const moveToNewVersion = () => {
        sessionStorage.setItem('editing-master-pattern', mp!.name!)
        dispatch(showProgressLine());
        showModal(LoadingMessage, {message: `Creating new version...`})
        return createNewVersionOfPatternSet(mp!, auth.loginInfo?.user?.name!, auth.loginInfo?.tenant?.schema)
            .then(newVersion => {
                dispatch(loadPatternSet(newVersion));
                dispatch(hideProgressLine())
                hideModal()
                return newVersion
            })
            .then((newVersion) => {
                const newID = newVersion.patterns.filter(pattern => pattern.name === selectedPattern!.name)[0].id
                goToNewVersion(newVersion.id, newID)
            })
    }

    const goToNewVersion = (setID: number, id: number) => {
        let path = props.fromProject? '/app/user/workflow/projects/patterns/' : '/app/user/workflow/master-patterns-library/'
        path = `${path}${setID}/${id}`
        navigate(path)
    }

    if (editEnabled && !newVersionInProgress && !props.fromProject && mp?.projectID === null) {
        moveToNewVersion()
    }

    const goBack = () => {
        props.fromProject ? navigate(-1) : navigate(`/app/user/workflow/master-patterns-library/${mp?.id}`)
    }

    return <div>
        <div className="page-wrapper">
            <ArrowBack className='arrow-back' onClick={() => goBack()} />
            <h1>{selectedPattern?.name}</h1>
            <div className="Tabs">
                {/* Tab nav */}
                <ul className="nav">
                    <li className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Pattern Details</li>
                    <li className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Search Patterns</li>
                </ul>
                <div className="outlet" style={{width: 1000}}>
                    <br/>
                    <div style={activeTab === "tab1" ? {} : { display: 'none' }}>
                        <DetailsTab pattern={startPattern} editEnabled={editEnabled} setEditEnabled={setEditEnabled}/>
                    </div>
                    <div style={activeTab === "tab2" ? {} : { display: 'none' }}>
                        <RegexTab pattern={startPattern} editEnabled={editEnabled} setEditEnabled={setEditEnabled}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function DetailsTab(props: {pattern: Pattern, editEnabled: boolean, setEditEnabled: (b: boolean) => void }) {
    const [name, setName] = useState(props.pattern.name);
    const [category, setCategory] = useState(props.pattern.category);
    const [color, setColor] = useState(props.pattern.color);
    const [description, setDescription] = useState(props.pattern.description);
    const dispatch = useAppDispatch();
    const auth = useAuthService();
    const mp : PatternSet | undefined = useAppSelector((state) => patternSetSelector(state, +props.pattern.master_id ));
    const { showModal } = useCustomModal();

    function handleColorSelected(color: string) {
        setColor(color);
        checkNameAndUpdate(name, category, color, description, props.pattern.id)
    }

    const checkNameAndUpdate = (name: string, category: string, color: string, description: string, id: number) => {
        //Check if the new name is already used. If so don't update.
        if (mp && mp.patterns && name !== props.pattern.name && mp.patterns.find(pattern => pattern.name === name)) {
            dispatch(showSnackbar({ message: `A pattern named ${name} already exists. Please choose a different name.`, type: "error" }));
        } else {
            update(name, category, color, description, id);
        }
    }

    const update = (name: string, category: string, color: string, description: string, id: number) => {
        const pattern : Pattern = {id: id, name: name, category: category, color: color, description: description, raw_pattern_location: props.pattern.raw_pattern_location, master_id: props.pattern.master_id} as Pattern;
        putApiPatternDetailsById(id, getPatternFormForUpload(pattern))
            .then(() => {
                dispatch(updatePattern(pattern))
                if (mp) {
                    dispatch(updateModifiedTimeOnPatternSet(getIdAndUpdatedTime(mp.id, auth.loginInfo?.user)))
                    return putApiPatternSet(mp)
                }
            })
            .then(() => {
                //Wait a little bit to do this because otherwise when the user clicks away from a text box that click event will immediately close the snackbar
                setTimeout(function() {
                    dispatch(showSnackbar({ message: "Updated pattern", type: "info" }))
                }, 100)
            })
            .catch(reason => {
                console.log(reason.message)
                dispatch(showSnackbar({ message: "Error setting pattern details", type: "error" }))
            })
    }

    return <div>
        {!props.editEnabled &&
            <div>
                <Button variant="contained" color="secondary"  onClick={() => {props.setEditEnabled(true)}}>Edit</Button>
                <br/>
                <br/>
            </div>
        }
        <table>
            <tbody>
            <tr>
                <td><label htmlFor="pname">Pattern name:</label></td>
                <td><input type="text" id="pname" name="pname" defaultValue={props.pattern.name} disabled={!props.editEnabled}
                           onChange={(x) => setName(x.target.value)}
                           onBlur={() => checkNameAndUpdate(name, category, color, description, props.pattern.id)}/></td>
            </tr>
            <tr>
                <td><label htmlFor="label">Category (Label):</label></td>
                <td><input type="text" id="label" name="label" defaultValue={props.pattern.category} disabled={!props.editEnabled}
                           onChange={(x) => setCategory(x.target.value.trim())}
                           onBlur={() => checkNameAndUpdate(name, category, color, description, props.pattern.id)}/><br/><br/></td>
            </tr>
            <tr>
                <td><label htmlFor="color">Markup Color:</label></td>
                <label className="wizard-color-circle" key={color}
                       onClick={() => { if (props.editEnabled) showModal(ColorPickerWizard, {onColorSelected:handleColorSelected})}}
                       style={{ backgroundColor: color}} />
            </tr>
            <tr>
                <td><label htmlFor="description">Description:</label></td>
                <td><TextField style={{width: 700}} type="text" id="description" name="description" disabled={!props.editEnabled}
                               defaultValue={props.pattern.description}
                               onChange={(x) => setDescription(x.target.value)}
                               onBlur={() => checkNameAndUpdate(name, category, color, description, props.pattern.id)}/><br/><br/></td>
            </tr>
            </tbody>
        </table>
        <br/>
    </div>
}

interface ColorPickerWizardProps {
    onColorSelected: (color: string) => void;
}

function ColorPickerWizard({ onColorSelected } : ColorPickerWizardProps) {
    const { hideModal } = useCustomModal();


    function handleBoxClick(color: string) {
        onColorSelected(color);
        hideModal()
    }

    return (
        <div className={"wizard-color-align"} style={{width: 260, height: 150}}>
            <div className="wizard-color-wrap">
                {colorPalette.map(o =>
                    <label key={o.color} className="wizard-color-circle"
                           onClick={() => handleBoxClick(o.color)}
                           style={{backgroundColor: o.color}}/>)}
            </div>
        </div>
    );
}

function RegexTab(props: {pattern: Pattern, editEnabled: boolean, setEditEnabled: (b: boolean) => void }) {
    const auth = useAuthService();
    const mp : PatternSet | undefined = useAppSelector((state) => patternSetSelector(state, +props.pattern.master_id ));

    let startRegexes: {description: string, rawPattern: string}[] = []
    const [regexes, setRegexes] = useState(startRegexes);
    //Do this just once
    useEffect(() => {
        console.log("loading from s3")
        getRawPatterns(props.pattern.raw_pattern_location)
            .then(result => {
                console.log("got the patterns")
                const raw_patterns = result
                setRegexes(parseRawPatternText(raw_patterns))
            })
            .catch(reason => {
                console.log("error getting patterns from s3")
                console.log(reason)
            })
        }, []
    )

    const dispatch = useAppDispatch();
    const update = (regexes: {description: string, rawPattern: string}[]) => {
        updatePatternTxtFile(regexes)
        if (mp) {
            dispatch(updateModifiedTimeOnPatternSet(getIdAndUpdatedTime(mp.id, auth.loginInfo?.user)))
            //do this just to update the modified time in the backend
            putApiPatternSet(mp)
        }
    }

    const updatePatternTxtFile = (regexes: {description: string, rawPattern: string}[]) => {
        const raw_pattern_text : string = parsePatternArray(regexes)
        const blob = new Blob([raw_pattern_text], { type: 'text/plain;charset=utf-8'});
        const pattern : Pattern = {raw_pattern_location: props.pattern.raw_pattern_location, id: props.pattern.id, name: props.pattern.name, category: props.pattern.category, description: props.pattern.description, master_id: props.pattern.master_id, color: props.pattern.color} as Pattern
        putApiPatternTextFileById(pattern.id, blob)
            .then(() => {
                dispatch(updatePattern(pattern))
                console.log("updated pattern file")
                //Wait a little bit to do this because otherwise when the user clicks away from a text box that click event will immediately close the snackbar
                setTimeout(function() {
                    dispatch(showSnackbar({ message: "Updated pattern", type: "info" }))
                }, 100)
            })
            .catch(reason => {
                console.log(reason.message)
                dispatch(showSnackbar({ message: "Error updating pattern", type: "error" }))
            })
    }

    const addRegex = () => {
        console.log("adding regex ")
        setRegexes(s => {
            return [
                ...s,
                {
                    description: "",
                    rawPattern: ""
                }
            ];
        });
    };

    const removeRegex = (i : number) => {
        console.log("removing regex " + i)
        const newList = regexes.filter((_, index) => index !== i)
        update(newList)
        setRegexes(newList);
    }

    const updateRegex = (i: number, regex: {description: string, rawPattern: string}) => {
        console.log("updating regex " + i)
        const copy = regexes.slice()
        copy[i] = regex
        setRegexes(copy)
    }

    return <div>
        {!props.editEnabled &&
            <div>
                <Button variant="contained" color="secondary"  onClick={() => {props.setEditEnabled(true)}}>Edit</Button>
                <br/>
                <br/>
            </div>
        }
        {regexes.map((item, i) => {
            return (
                <table key={i}>
                    <tbody>
                    <tr>
                        <td><label htmlFor="description">Description:</label></td>
                        <td><input onChange={(x) => updateRegex(i, {description: x.target.value, rawPattern: item.rawPattern})} style={{width: 700}} defaultValue={item.description} type="text" id="description" name="description"
                                   onBlur={() => update(regexes)} disabled={!props.editEnabled} key={`description-${i}/${regexes.length}`} /></td>
                        <td><Delete className="delete-icon" style={{float: 'right', margin: "10"}} onClick={() => {if (props.editEnabled) removeRegex(i)}}/></td>
                    </tr>
                    <tr>
                        <td><label htmlFor="regex">Search Pattern:</label></td>
                        <td><input onChange={(x) => updateRegex(i, {description: item.description, rawPattern: x.target.value})} style={{width: 700}} defaultValue={item.rawPattern} type="text" id="regex" name="regex"
                                   onBlur={() => update(regexes)} disabled={!props.editEnabled} key={`pattern-${i}/${regexes.length}`} /></td>
                    </tr>
                    </tbody>
                </table>
            );
        })}
        <br/>
        <Button variant="contained" color="secondary" disabled={!props.editEnabled} onClick={ () => {addRegex()}}>Add New Pattern</Button>
    </div>
}

function parseRawPatternText(text : string) : {description: string, rawPattern: string}[]{
    const rows : string[] = text.split("\n");
    let parsedPatterns : {description: string, rawPattern: string}[] = [];
    let description = "";
    rows.forEach(row => {
        //Comments above a regex are used for descriptions.
        if (row.startsWith("#")) {
            description += row.substring(1)
        } else {
            parsedPatterns.push({description: description, rawPattern: row});
            description = ""
        }
    })

    return parsedPatterns;
}

function parsePatternArray(array: {description: string, rawPattern: string}[]) : string {
    let text = ""
    array.forEach(pattern => {
        if (pattern.description.length > 0) {
            text += "#" + pattern.description + "\n"
        }
        text += pattern.rawPattern + "\n"
    })
    //Delete trailing new line
    if (text.endsWith("\n")) {
        text = text.substring(0, text.length-1)
    }
    return text
}

async function getRawPatterns(location: string): Promise<string> {
    if (location.length === 0) {
        return "";
    }
    if (location) {
        const result = await getFileNoCache(location)
        return result.toString()
    } else {
        return "";
    }
}

//This is the same logic that Docs uses. Each regex is wrapped in parentheses like "(?:regex)"
//Then they are all joined together with the OR operator.
//This makes one big pattern that works for anything in the category
function compileRegexes(regexes: {description: string, rawPattern: string}[]): string {
    if (regexes.length === 0 || regexes.every(regex => !regex.rawPattern)) {
        return ''
    }
    let patternText = regexes.filter(regex => regex.rawPattern) //filter regexes where the pattern is empty. These cause errors in the doc viewer
        .map(regex => surroundWithNonMatchingGroupParens(cleanUpRegex(regex.rawPattern))).join("|")
    patternText = surroundWithNonMatchingGroupParens(patternText)
    return patternText
}

export function hasJapaneseCharacters(s: string) {
    try {
        const regex = /[\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}]/u;
        if (regex.test(s)) {
            console.log("Found Japanese characters in this pattern. Word boundaries will be turned off because Japanese writing doesn't have spaces between words.")
            return true;
        }
    } catch (err) {
        console.log(err)
    }
    return false;
}

function cleanUpRegex(regex: string) {
    if (regex.startsWith('=')) {
        regex = regex.substring(1)
    }
    //Lets the user type BEFORE and AFTER instead of memorizing the syntax for lookbehinds/lookaheads.
    regex = regex.replaceAll("BEFORE(", "(?<=")
    regex = regex.replaceAll("AFTER(", "(?=")
    return regex.trim()
}

function surroundWithNonMatchingGroupParens(pattern: string): string {
    return "(?:" + pattern + ")";
}

//Combine the name and category into one string using a predefined delimiter. This gets send to the custom webviewer
//so that the type field on a search result stores both the name and category.
function getStyleAndDisplayCategory(name: string, category: string): string {
    return (category && category !== name) ? category + CATEGORY_SPLITTER + name : name
}

export async function getPatternsForDocViewer(patterns: Pattern[]): Promise<any[]> {
    let redactSearchPatterns : any[] = []
    for (let pattern of patterns) {
        try {
            const rawPatterns: string = await getRawPatterns(pattern.raw_pattern_location)
            const regexes = parseRawPatternText(rawPatterns)
            const finalPattern = compileRegexes(regexes)
            //Webviewer compiles these regexes in C++ using the Perl syntax (see https://www.boost.org/doc/libs/1_83_0/libs/regex/doc/html/boost_regex/syntax/perl_syntax.html)
            //So errors happen if one of these regexes can compile in Javascript but not in Perl. In our app, this has
            //only come up because Javascript regexes can use non-fixed width lookbehinds but Perl cannot. So for now
            //we just check for that. It would be better to actually compile the regex in Perl here, but I think that
            //would require bundling Perl with the frontend.
            if (hasNonFixedWidthLookbehind(finalPattern)) {
                throw new Error('non-fixed width lookbehinds will not work in webviewer')
            }

            //The label is what will be displayed in the search bar.
            //The type is used to specify that a mark should use CCI or CBI style even if it's not named that.
            const redactSearchPattern: any = {label: pattern.name, type: getStyleAndDisplayCategory(pattern.name, pattern.category), regex: finalPattern? new RegExp(finalPattern, 'im') : undefined}
            //i for case-insensitive
            //m for multi-line
            //Don't use g for global here. In the custom webviewer, it tests each regex against the result string to assign the correct type.
            //If the g flag is included then every other check will return false even if it matches
            //https://stackoverflow.com/questions/4950463/regex-in-javascript-fails-every-other-time-with-identical-input
            redactSearchPatterns.push(redactSearchPattern)

        } catch (error) {
            console.log(`Error converting pattern ${pattern.name} into the format for the doc viewer`)
            console.log(error)
        }
    }
    return redactSearchPatterns
}

//Looks for the group (?<= ...) and sees if anything inside that group is *, +, ?, or a range like {1,10}
const nonFixedWidthLookbehind = /\(\?<=(?:[^)]*?\{(?:\d*,\d*|,\d+)\}[^)]*?|[^)]*?\*[^)]*?|[^)]*?\+[^)]*?|[^)(]*?\?[^)]*?)\)/;

function hasNonFixedWidthLookbehind(pattern: string): boolean {
    return nonFixedWidthLookbehind.test(pattern);
}
