import React, {useContext, useState} from 'react';
import {useCustomModal} from "../../pages/modals/custom-message-modal";
import {useAppSelector, useAppDispatch} from "../../hooks/redux-hook";
import {pageMarginsSelector, setFooter, setHeader, setLeft, setRight} from "../../redux/pagemargins";
import {selectProject} from "../../redux/projects";
import WebViewerContext from "../../contexts/webviewer-context";
import Button from "@mui/material/Button";


const BulkSearchTerms = () => {
    const { hideModal } = useCustomModal();
    const { setInstance,instance } = useContext(WebViewerContext);

    const pageMargins = useAppSelector(state => pageMarginsSelector(state));
    const [searchTerms, setSearchTerms] = useState("")


    const handleSubmit = () => {
        hideModal()
        instance.Core.documentViewer.trigger('bulkSearchTerms', searchTerms)
    };

    return (
        <div style={{'maxWidth': '530px'}}>
            <h1>Bulk Search Terms</h1>
            <div style={{padding: '0px 20px 20px 20px'}}>
            <p>Copy a column from Excel/CSV containing terms like Participant IDs. dates, names, or confidential information, then paste below.</p>
            <div style={{width: 'inherit'}}>
                <textarea placeholder={'Paste column data here'}
                       style={{backgroundColor: 'inherit', alignItems: "center", justifyContent: "center", height: 400, width: 480, margin: "auto"}}
                       onChange={(event => {
                           setSearchTerms(event.target.value)
                       })}/>
            </div>
            </div>
            <div className={'container'}>
                <div style={{textAlign: "center"}}>
                    <Button color="secondary" variant="outlined" onClick={hideModal} style={{marginRight: 40}}>CANCEL</Button>
                    <Button disabled={searchTerms===''} onClick={handleSubmit} type="submit" color="secondary" variant="contained">
                        SUBMIT
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default BulkSearchTerms;
