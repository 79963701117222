import React, {useEffect, useState, useContext} from 'react';
import '../Panel.scss'
import WebViewerContext from "../../../contexts/webviewer-context";
import RedactionSearchMultiSelect from "./RedactionSearchMultiSelect";
import PageRangeInput from "../../PageRangeInput";
import SearchStatus from "./SearchStatus";
import RedactionSearchResultsContainer from "./RedactionSearchResultsContainer";
import {useCustomModal} from "../../../pages/modals/custom-message-modal";
import ChangeMarginsModal from "../ChangeMarginsModal";
import {Checkbox, Menu, MenuItem, Tooltip} from "@mui/material";
import {loadNLPRedactionSearch} from "../documentSearch";
import {StyledCloseIconUploader} from "../../close-button";
import Dropzone from "react-dropzone-uploader";
import {FileState} from "../../../models/FileState";
import {showSnackbar} from "../../../redux/snackbar";
import FilterFunctionContext from "../../../contexts/filter-function-context";
import {roleMultiSelectRenderValue} from "../../../services/utils";
import BulkSearchTerms from "../BulkSearchTerms";

export const SearchPanel = (props: {redactionSearchPatterns: {label: string, type: string, regex: RegExp}[], selectedTab: any}) => {
    const { setInstance,instance } = useContext(WebViewerContext);
    const {filterFunction} = useContext(FilterFunctionContext);
    const { showModal, hideModal } = useCustomModal();
    const [anchorEl, setAnchorEl] = useState(null);
    const [pageRange, setPageRange] = useState<(number|undefined)[]|undefined>(undefined);
    const [wholeWord, setWholeWord] = useState<boolean>(true);
    const [redactionSearchResults, setRedactionSearchResults] = useState<any[]>([]);
    const [isProcessingRedactionResults, setIsProcessingRedactionResults] = useState<boolean>(false);
    const [searchStatus, setSearchStatus] = useState<string>(SearchStatus.SEARCH_NOT_INITIATED);
    const [searchTerms, setSearchTerms] = useState<{ label: any; value: any; type: string; }[]>();

    function pageRangeOnClear() {
        setPageRange(undefined)
    }

    const deleteRedactionSearchResults = (results: any[], resultsToDelete: any[], selectNextSearchResult: boolean) => {
        //This shouldn't happen, but if it does we want to abort or it will delete all the search results.
        if (results.length === 0) {
            return;
        }
        //If this is deleting the active search result, save the index of that for later.
        let index = -1
        if (resultsToDelete.length === 1 && resultsToDelete[0] === instance.Core.documentViewer.getActiveSearchResult()) {
            index = results.indexOf(resultsToDelete[0])
        }

        const remainingSearchResults = results.filter(item => !resultsToDelete.includes(item))
        instance.Core.documentViewer.clearSearchResults()
        instance.Core.documentViewer.displayAdditionalSearchResults(remainingSearchResults)
        setRedactionSearchResults(remainingSearchResults)
        //Need to pass the array of search results as a parameter on another object. When it was being passed directly
        //it was coming up as undefined on the event listener.
        instance.Core.documentViewer.trigger('updateSearchResultsByDocument', {'searchResults': remainingSearchResults})
        //If we deleted the active search result, then navigate to the next one if that parameter is true.
        //That should be true when you delete a search result, but not when you add it as a mark. In that
        //case the viewer should stay where it is so that you can see the mark you just made.
        if (index !== -1 && selectNextSearchResult) {
            if (index < remainingSearchResults.length) {
                instance.Core.documentViewer.setActiveSearchResult(remainingSearchResults[index])
            } else if (index > 0) {
                instance.Core.documentViewer.setActiveSearchResult(remainingSearchResults[index - 1])
            }
        }

        setSearchStatus(SearchStatus['SEARCH_DONE']);
        setTimeout(() => {
            setIsProcessingRedactionResults(false);
        }, 100);
    };

    const deleteAISearchResults = () => {
        deleteRedactionSearchResults(redactionSearchResults, getAIGeneratedSearchResults(), true)
    }

    const clearPatternsSearchResults = () => {
        let indexesToDelete: any[] = []
        redactionSearchResults.forEach((result, index) => {
            if (!result.AIGenerated) {
                indexesToDelete.push(result)
            }
        })
        deleteRedactionSearchResults(redactionSearchResults, indexesToDelete, true)
    }

    const getAIGeneratedSearchResults = () => {
        return redactionSearchResults.filter((result)=> result.AIGenerated)
    }

    const onCancelSearch = (clearAll: boolean) => {
        if (clearAll) {
            setSearchTerms([]);
            setRedactionSearchResults([]);
            clearPatternsSearchResults()
            setIsProcessingRedactionResults(false);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const onSearchResultsChanged = (newSearchResults: any[]) => {
            setRedactionSearchResults(newSearchResults)
        }

        if (instance) {
            const documentViewer = instance.Core.documentViewer;
            documentViewer.addEventListener('searchResultsChanged', onSearchResultsChanged)
        }
        return () => {
            if (instance) {
                instance.Core.documentViewer.removeEventListener('searchResultsChanged', onSearchResultsChanged)
            }
        }
    }, [instance]);

    const handleAdvanceOptionsClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    return <div className={'panel'} style={{height: '1000px'}}>
        <RedactionSearchMultiSelect searchTerms={searchTerms} setSearchTerms={setSearchTerms}
                                    redactionSearchPatterns={props.redactionSearchPatterns} pageRange={pageRange}
                                    wholeWord={wholeWord}
                                    setSearchStatus={setSearchStatus} resultsToKeep={getAIGeneratedSearchResults()}/>
        <div className="search-options-container">
            <div>
            <div className="extra-options">
                <button className='Button' onClick={handleAdvanceOptionsClick}>Advanced Options
                    {Boolean(anchorEl) ? "  ↑" : "  ↓"}</button>
            </div>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={'transform-menu'}
                    style={{ zIndex: 99999 }}
                >
                    <MenuItem onClick={() => {handleClose();showModal(ChangeMarginsModal, {})}}>Change Margins</MenuItem>
                    <MenuItem title={'Bulk search allows you to add a list of terms to be searched within the currently displayed document'}
                              onClick={() => {handleClose();showModal(BulkSearchTerms, {})}}>Bulk Search Terms</MenuItem>
                    <MenuItem onClick={() => {handleClose();showModal(UploadNlpResults, {
                        instance,
                        setSearchStatus,
                        deleteAISearchResults,
                        filterFunction
                    })}}>Load AI Annotations</MenuItem>
                    <MenuItem onClick={() => {setWholeWord(prevState => !prevState)}}>
                        <input type={"checkbox"} readOnly={true}
                               checked={wholeWord} style={{marginRight: 10}}/> Whole Words</MenuItem>
                </Menu>
            </div>
            <div style={{marginLeft: "auto", marginBottom: "auto"}}>
                <PageRangeInput pageRangeChangeCallBack={(newPageRange) => setPageRange(newPageRange)}
                                clearPageRangeCallBack={pageRangeOnClear} placeholder={'Page Range e.g. 1-10'}/>
            </div>
        </div>

        <RedactionSearchResultsContainer
            redactionSearchResults={redactionSearchResults}
            deleteSelectedResults={deleteRedactionSearchResults}
            onCancelSearch={onCancelSearch}
            searchStatus={searchStatus}
            isProcessingRedactionResults={isProcessingRedactionResults}
            selectedTab={props.selectedTab}
        />
    </div>;
};

export function UploadNlpResults(props : { instance: any, setSearchStatus: (s: string) => void, deleteAISearchResults: () => void, filterFunction: any}) {
    const { hideModal } = useCustomModal();

    const handleSubmit = async (files: { file: any; }[], allFiles: any[]) => {
        const filetext = await files[0].file.text()
        const json = JSON.parse(filetext)
        props.deleteAISearchResults()
        loadNLPRedactionSearch(props.instance.Core.documentViewer, json, props.instance.Core.Search.Mode, props.setSearchStatus, props.filterFunction.function);
        hideModal()
    }

    return (
        <div className="upload-file-modal-container">
            <div className="wizard-title">
                <StyledCloseIconUploader onClick={hideModal} />
                <span>{"Upload AI Annotations"}</span>
            </div>
            <Dropzone
                onSubmit={handleSubmit}
                maxFiles={1}
                accept={".json"}
                inputContent="Click here or drag and drop"
                submitButtonDisabled={files => files.length < 1}
                styles={{ dropzone: { minHeight: 450, minWidth: 400, padding:8 } }}
            />
        </div>)
}