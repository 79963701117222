
export class Buttons{
    type: string = '';
    img: string = '';
    label: string = '';
    dataElement: string = '';
    onClick:any;
    className: string = '';
    button: boolean = true;
    title:string = ''
    constructor(label:string, dataElement:string, action:()=>void, img= '', type = 'actionButton') {
        this.type = type;
        this.img = img;
        this.label = label;
        this.dataElement = dataElement;
        this.onClick = () => action()
    }
    setClassName(className:string): Buttons {
        this.className = className;
        return this;
    }
    setTitle(title:string): Buttons {
        this.title = title;
        return this;
    }
    makeActive(iframeDoc: any) {
        iframeDoc!.querySelector(`[data-element="${this.dataElement}"]`)!.classList.add('active');
    }
    makeInActive(iframeDoc: any) {
        iframeDoc!.querySelector(`[data-element="${this.dataElement}"]`)!.classList.remove('active');
    }
}

export class CustomModal{
    dataElement: string = '';
    disableBackdropClick: boolean = true;
    disableEscapeKeyDown: boolean  = true;
    header:Record<string, string> = {}
    body:any = {};
    footer: any = {}
    constructor(dataElement:string, disableBackdropClick: boolean = true) {
        this.dataElement = dataElement;
        this.disableBackdropClick = disableBackdropClick;
    }
    addHeader({title, className}: {title: string, className: string}): CustomModal {
        this.header['title'] = title;
        this.header['className'] = className;
        return this;
    }
    addBody(children: any, style: any): CustomModal {
        this.body['style'] = {...style};
        this.body['children'] = [children];
        return this;
    }
    addFooter( instance?: any, children?: any,className?: string): CustomModal {
        const cancelButton  = new Buttons('', 'cancelButton', ()=>instance!.UI!.closeElements([this.dataElement]), '', 'button');
        cancelButton.setTitle("Ok")
        cancelButton.setClassName("modal-button cancel-form-field-button");
        this.footer['className'] = `myCustomModal-footer footer ${className}`;
        this.footer['children'] = [cancelButton];
        return this;
    }
}

export class CompareModalBody{
    static createHtmlElement(htmlElement: string){
        return document.createElement(htmlElement);
    }
    static displayFiles(){
        const div = this.createHtmlElement('div')
        return this.renderFile(div)
    }
    static renderFile(div: HTMLElement){
        const row = this.createHtmlElement("div");
        row.innerHTML = `
           <div style="text-align: center;">
                <div>
                    <img src="/lg.gif" alt="loader" style="width: 200px"/>
                </div>
                <p style="margin-bottom: 0; margin-top: -30px;font-weight: 200; text-transform: uppercase; color: #000">Comparing files<br/><small>PLEASE WAIT...</small></p>
           </div> 
        `;
        div.appendChild(row);
        return div;
    }
}