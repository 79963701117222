import React, {useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import '../../components/OutlineColorPickerModal.scss'
import '../../components/AnnotationRepeatPopup.scss'
import {StyledCloseIconUploader} from "../../components/close-button";
import {DataGrid, GridColDef, GridRowsProp} from "@mui/x-data-grid";

export default function BatchReportModal(props: {fileNamesWithTabIndexes: {fileName: string, index: number}[], createBatchReports: (tabIndexes: number[]) => void}) {
    const [selectedFilesTabIndexes, setSelectedFileTabIndexes] = useState<number[]>(props.fileNamesWithTabIndexes.map((fi) => fi.index))
    const {hideModal} = useCustomModal();

    const handleCancelClick = () => {
        hideModal()
    }

    const handleOkClick = () => {
        hideModal()
        props.createBatchReports(selectedFilesTabIndexes);
    }

    const handleChange = (event: any, tabIndex: number) => {
        if (event.target.checked) {
            setSelectedFileTabIndexes(selectedFilesTabIndexes.concat(tabIndex))
        } else {
            setSelectedFileTabIndexes(selectedFilesTabIndexes.filter((selectedFilesTabIndex) => selectedFilesTabIndex!==tabIndex))
        }
    }

    function getRows() {
        let rows: GridRowsProp = [];
        props.fileNamesWithTabIndexes.forEach((fileNamesWithTabIndex) => {
            rows = rows.concat({
                id: fileNamesWithTabIndex.index,
                col1: fileNamesWithTabIndex.fileName,
            });
        })
        return rows;
    }

    let columns: GridColDef[]
    columns = [{
            field: 'col1', headerName: 'File Name', flex: 15, cellClassName: 'cell-left-border', headerClassName: 'cell-left-border'
        }]


    return (
        <div style={{margin: 20}}>
            <div className="wizard-title" style={{marginBottom: 20}}>
                <StyledCloseIconUploader onClick={hideModal} />
                <span>{"Batch Report"}</span>
            </div>
            <p style={{marginTop: 40, marginBottom: 20}}>Select what files to include in the batch report</p>
                <div>
                    <DataGrid
                        rowHeight={30}
                        // initialState={{ pagination: {pageSize: 10}}}
                        style={{ height: "calc(100vh - 604px)", marginBottom: 0, width: 500 }}
                        rows={getRows()}
                        columns={columns}
                        hideFooter={true}
                        // pagination
                        checkboxSelection
                        selectionModel={selectedFilesTabIndexes}
                        onSelectionModelChange={(ids: any) => {
                            // @ts-ignore
                            setSelectedFileTabIndexes(ids)
                        }}
                    />
            </div>
            <div className="button-container">
                <button className="rounded-button cancel-button" onClick={handleCancelClick}>
                    Cancel
                </button>
                <button className="rounded-button ok-button" onClick={handleOkClick}
                        disabled={selectedFilesTabIndexes.length === 0}>OK
                </button>
            </div>
        </div>
    )
}

