import "../pages.scss";
import "./roles-overview.scss";
import { User } from "../../models/User";
import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { Account } from "../../models/Account";
import { getApiRlsAccounts } from "../../services/rls.account";
import { postApiRlsUsersSearch } from "../../services/rls.user";
import { InternalUser } from "../../models/InternalUser";
import { ArrowBack } from "@mui/icons-material";
import {
    accountManagerBody,
    adminRoleBody,
    adminRolePermissions,
    pmRoleBody,
    fmRoleBody,
    pmRolePermissions,
    superAdminBody,
    superAdminPermissions,
    userRoleBody, userRolePermissions,
    redactionRoleBody, redactionRolePermissions,
    anonymizeRoleBody, anonymizeRolePermissions,
    viewRoleBody, viewRolePermissions, fmRolePermissions
} from "../../constants";
import { useAppDispatch } from "../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";

export enum roles {
    SUPER = "Super Admin",
    ADMIN = "Administrator",
    PM = "Project Manager",
    FM = "File Manager",
    USER = "Member",
    VIEW = "View only user",
    REDACTION = "Redaction",
    ANONYMIZE = "Anonymize",
    AM = "Multi-account access"
}

function RolePanel(props: { role: string, users: User[], showPage: (role: string) => void }) {

    return <Paper elevation={3} className="role-panel" onClick={() => props.showPage(props.role)}>
        <h6 style={{ marginBottom: 16 }}>{props.role}</h6>
        {props.users.length > 0 ?
            <div className="role-panel-assigned">
                Assigned to <span style={{ fontWeight: 600, textDecoration: "underline" }}>
                    {props.users.length} user{props.users.length !== 1 && "s"}</span>
            </div> :
            <div className="role-panel-assigned">
                No users assigned
            </div>
        }
    </Paper>
}

const sortByAccountAndName = function (a: InternalUser, b: InternalUser) {
    const tenantA = a.tenant?.name;
    const tenantB = b.tenant?.name;
    const nameA = a.tenant?.user?.name;
    const nameB = a.tenant?.user?.name;
    if (!tenantA) {
        return -1;
    }
    if (!tenantB) {
        return 1;
    }
    if (tenantA === tenantB) {
        if (!nameA) {
            return -1;
        }
        if (!nameB) {
            return 1;
        }
    }
    return (tenantA < tenantB) ? -1 : (tenantB > tenantA) ? 1 : (nameA && nameB && nameA < nameB) ? -1 : 1
}

export function RolesOverview() {

    const dispatch = useAppDispatch();
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [users, setUsers] = useState<InternalUser[]>();
    const [showRolePage, setShowRolePage] = useState<roles | undefined>();

    useEffect(() => {
        const controller = new AbortController();
        dispatch(showProgressLine());
        getApiRlsAccounts(controller.signal)
            .then(accounts => {
                setAccounts(accounts);
                postApiRlsUsersSearch({}, controller.signal)
                    .then(accountUsers => setUsers(accountUsers));
            })
            .then(() => dispatch(hideProgressLine()))
            .catch(() => dispatch(hideProgressLine()));
        return () => {
            controller.abort();
            dispatch(hideProgressLine());
        }
    }, []);

    const getUsers = (role: roles) => {
        const userRole = Object.keys(roles)[Object.values(roles).indexOf(role as roles)];
        return users!.filter(user => user.role as string === userRole || user.accountRoles?.find(accountRole => accountRole as string === userRole));
    }

    const getBodyText = (role: roles) => {
        switch (role) {
            case roles.ADMIN:
                return adminRoleBody;
            case roles.PM:
                return pmRoleBody;
            case roles.FM:
                return fmRoleBody;
            case roles.USER:
                return userRoleBody;
            case roles.REDACTION:
                return redactionRoleBody;
            case roles.ANONYMIZE:
                return anonymizeRoleBody;
            case roles.VIEW:
                return viewRoleBody;
            case roles.AM:
                return accountManagerBody;
            case roles.SUPER:
                return superAdminBody;
            default:
                return ""
        }
    }

    return <div className="page-wrapper">
        {showRolePage === undefined ?
            <>
                <div className="page-header" style={{ marginTop: 24 }}>Roles overview</div>
                <div className="roles-overview-body">
                    This is an overview with all the roles and the number of assigned users from all the registered
                    accounts.<br />
                    A role provides access to predefined permissions for actions and features within the web app.<br />
                    You can view the permissions and the users assigned within that role by clicking on it.<br />
                </div>
                <div className="user-roles-title">System roles</div>
                {users !== undefined &&
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <RolePanel key={roles.SUPER} role={roles.SUPER} users={getUsers(roles.SUPER)}
                            showPage={() => setShowRolePage(roles.SUPER)} />
                        <RolePanel key={roles.AM} role={roles.AM} users={getUsers(roles.AM)}
                            showPage={() => setShowRolePage(roles.AM)} />
                    </div>
                }
                <div className="user-roles-title">Account roles</div>
                {users !== undefined &&
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <RolePanel key={roles.ADMIN} role={roles.ADMIN} users={getUsers(roles.ADMIN)}
                            showPage={() => setShowRolePage(roles.ADMIN)} />
                        <RolePanel key={roles.PM} role={roles.PM} users={getUsers(roles.PM)}
                            showPage={() => setShowRolePage(roles.PM)} />
                        <RolePanel key={roles.FM} role={roles.FM} users={getUsers(roles.FM)}
                                   showPage={() => setShowRolePage(roles.FM)} />
                        <RolePanel key={roles.ANONYMIZE} role={roles.ANONYMIZE} users={getUsers(roles.ANONYMIZE)}
                                   showPage={() => setShowRolePage(roles.ANONYMIZE)} />
                        <RolePanel key={roles.REDACTION} role={roles.REDACTION} users={getUsers(roles.REDACTION)}
                                   showPage={() => setShowRolePage(roles.REDACTION)} />
                        <RolePanel key={roles.USER} role={roles.USER} users={getUsers(roles.USER)}
                            showPage={() => setShowRolePage(roles.USER)} />
                        <RolePanel key={roles.VIEW} role={roles.VIEW} users={getUsers(roles.VIEW)}
                            showPage={() => setShowRolePage(roles.VIEW)} />
                    </div>
                }
            </>
            :
            <>
                <div className="page-header">
                    <ArrowBack className='arrow-back' onClick={() => setShowRolePage(undefined)} />
                    {showRolePage}
                </div>
                <div dangerouslySetInnerHTML={{ __html: getBodyText(showRolePage) }}></div>
                {showRolePage !== roles.AM && <div className="user-roles-title">Permissions</div>}
                {showRolePage === roles.ADMIN &&
                    <div style={{ display: "flex" }}>
                        <div className="permissions-column">
                            {adminRolePermissions.slice(0, 8).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {adminRolePermissions.slice(8, 16).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {adminRolePermissions.slice(16, 24).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {adminRolePermissions.slice(24, 31).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                    </div>
                }
                {showRolePage === roles.PM &&
                    <div style={{ display: "flex" }}>
                        <div className="permissions-column">
                            {pmRolePermissions.slice(0, 6).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {pmRolePermissions.slice(6, 12).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {pmRolePermissions.slice(12, 18).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {pmRolePermissions.slice(18, 24).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                    </div>
                }
                {showRolePage === roles.FM &&
                    <div style={{ display: "flex" }}>
                        <div className="permissions-column">
                            {fmRolePermissions.slice(0, 4).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {fmRolePermissions.slice(4, 8).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {fmRolePermissions.slice(8, 12).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                    </div>
                }
                {showRolePage === roles.USER &&
                    <div style={{ display: "flex" }}>
                        <div className="permissions-column">
                            {userRolePermissions.slice(0, 4).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {userRolePermissions.slice(4, 7).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                    </div>
                }
                {showRolePage === roles.REDACTION &&
                    <div style={{ display: "flex" }}>
                        <div className="permissions-column">
                            {redactionRolePermissions.slice(0, 4).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {redactionRolePermissions.slice(4, 8).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {redactionRolePermissions.slice(8, 11).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                    </div>
                }
                {showRolePage === roles.ANONYMIZE &&
                    <div style={{ display: "flex" }}>
                        <div className="permissions-column">
                            {anonymizeRolePermissions.slice(0, 4).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {anonymizeRolePermissions.slice(4, 8).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                        <div className="permissions-column">
                            {anonymizeRolePermissions.slice(8, 11).map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                    </div>
                }
                {showRolePage === roles.VIEW &&
                    <div style={{ display: "flex" }}>
                        <div className="permissions-column">
                            {viewRolePermissions.map(p =>
                                <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                        </div>
                    </div>
                }
                {showRolePage === roles.SUPER && <div style={{ display: "flex" }}>
                    <div className="permissions-column">
                        {superAdminPermissions.slice(0, 8).map(p =>
                            <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                    </div>
                    <div className="permissions-column">
                        {superAdminPermissions.slice(8, 16).map(p =>
                            <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                    </div>
                    <div className="permissions-column">
                        {superAdminPermissions.slice(16, 24).map(p =>
                            <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                    </div>
                    <div className="permissions-column">
                        {superAdminPermissions.slice(24, 31).map(p =>
                            <span key={p} style={{ marginBottom: 9 }}><span className="dot" />{p}</span>)}
                    </div>
                </div>
                }
                {getUsers(showRolePage).length > 0 &&
                    <>
                        <div className="user-roles-title" style={{ fontWeight: "600", fontSize: 21 }}>
                            {showRolePage !== roles.AM ? "Assigned to users:" : "Users with multi-account access:"}
                        </div>
                        <div className="user-row" style={{ fontSize: 11, border: "none" }}>
                            <div style={{ width: 307, marginLeft: 24 }}>NAME</div>
                            <div style={{ width: 307, marginLeft: 24 }}>ACCOUNT</div>
                            <div style={{ width: 307, marginLeft: 24 }}>EMAIL</div>
                        </div>
                        {getUsers(showRolePage).sort(sortByAccountAndName).map(user => <UserRow key={user.id} user={user} accounts={accounts} />)}
                    </>
                }
            </>
        }
    </div>
}

function UserRow(props: { user: InternalUser, accounts: Account[] }) {
    return <div className="user-row">
        <div style={{ width: 307, marginLeft: 24 }}>{props.user.tenant?.user?.name || props.user.name || "-"}</div>
        <div style={{ width: 307, marginLeft: 24 }}>{props.accounts.find(a => a.id === props.user.accountId)?.name}</div>
        <div style={{ width: 307, marginLeft: 24 }}>{props.user.email}</div>
    </div>
}
