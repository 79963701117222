import { useTheme } from '@mui/material/styles';
import { Avatar, Button } from "@mui/material";
import { ProjectCreateWizard } from "../pages/user/projects/wizards/project-create-wizard";
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthService, ViewPerspective } from '../contexts/auth-context';
import RLS_Protect_Logo from "../provisional_icons/RLS_Protect_logo.png";
import RLS_Reveal_Logo from "../provisional_icons/RLS_Reveal_logo.png";
import RLS_Protect_Icon from "../provisional_icons/RLS_Protect_Icon.png";
import baseline_expand_less from "../provisional_icons/baseline-expand-less.png";
import baseline_expand_more from "../provisional_icons/baseline-expand-more.png";
import baseline_arrow_back from "../provisional_icons/baseline-arrow-back.png";
import Collapsible from 'react-collapsible';
import './menu.scss';
import moment from 'moment';
import { getProjectByTaskId, setCookie } from '../services/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppDispatch, useAppSelector } from "../hooks/redux-hook";
import {
    loadedProjectsSelector,
    projectsSelector, selectedProjectSelector,
    selectProject,
    setProjectsLoaded
} from "../redux/projects";
import { Project } from "../models/Project";
import {
    projectTemplatesLoadedSelector,
    projectTemplatesSelector
} from "../redux/projectTemplates";
import {fetchCustomFields, fetchPatternSets, fetchProjects, fetchTemplates, fetchUsers} from "../services/init";
import { useCustomModal } from '../pages/modals/custom-message-modal';
import { RlsProtectUnavailableModal } from '../pages/modals/modal-content/rls-protect-unavailable-modal';
import { RlsProtectNoAccessModal } from '../pages/modals/modal-content/rls-protect-no-access-modal';
import { useAdminService } from '../contexts/super-admin-context';
import { setAccountList } from '../redux/account-list';
import {PatternSetsSelector} from "../redux/patternSets";

function TabButton(p: { isSelected: boolean, text: string, onPress: () => void,
    expandable?: boolean, isExpanded?: boolean }) {
    const theme = useTheme();
    const [hover, setHover] = useState(false);

    return <>
    <div onMouseEnter = {() => setHover(true)} onMouseLeave = {() => setHover(false)}
        onClick={p.onPress} className="tab-button-menu" style={{ fontWeight: p.isSelected ? "600" : "normal",
        backgroundColor: p.isSelected ? theme.palette.lightGrayishBlue3.main : hover ? "#F5F6F7" : "white" }}
    >
        <span className="tab-button-menu-text" >{p.text}</span>
        {p.expandable ?
            <ExpandMoreIcon style={{ fill: "#939598", transform: `rotate(${p.isExpanded ? 0 : "-90deg" })`}} />
            : null
        }
    </div>
    {!p.isExpanded && <div style = {{borderBottom: "1px solid #F5F6F7"}}/>}
    </>
}

function AdminMenu() {
    const [selected, setSelected] = useState<"Accounts" | "Roles overview" | "System users" | "Users">("Accounts");
    const nav = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.startsWith("/app/admin/account")) {
            setSelected("Accounts");
        }
        if (location.pathname.startsWith("/app/admin/roles-overview")) {
            setSelected("Roles overview");
        }
        if (location.pathname.startsWith("/app/admin/system-users")) {
            setSelected("System users");
        }
        if (location.pathname.startsWith("/app/admin/users")) {
            setSelected("Users");
        }
    }, [])

    return <div className="menu-wrapper" >
        <TabButton
            isSelected={selected === "Accounts"}
            text="Accounts"
            onPress={() => {
                setSelected("Accounts");
                nav("/app/admin/accounts");
            }}
            isExpanded
        />
        <TabButton
            isSelected={selected === "Roles overview"}
            text="Roles overview"
            onPress={() => {
                setSelected("Roles overview");
                nav("/app/admin/roles-overview");
            }}
            isExpanded
        />
        <TabButton
            isSelected={selected === "System users"}
            text="System users"
            onPress={() => {
                setSelected("System users");
                nav("/app/admin/system-users");
            }}
            isExpanded
        />
        <TabButton
            isSelected={selected === "Users"}
            text="Users"
            onPress={() => {
                setSelected("Users");
                nav("/app/admin/users");
            }}
            isExpanded
        />
    </div>
}

function CategoryMenuItem(p: { text: string, onPress: () => void, isDisabled: boolean }) {
    const [isHovering, setIsHovering] = useState(false);

    return <div
        onClick={p.onPress}
        className="category-item-menu-wrapper"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{
            fontWeight: isHovering ? "600" : "normal",
        }}>
        <span className="category-item-menu-text" style={{ color: !p.isDisabled ? "black" : "#939598" }} >{p.text}</span>
    </div>
}

function CategoryMenuExpandable(p: { categoryMenuItems: JSX.Element[] }) {
    const [isMenuOpen, setIsMenuOpen] = useState(true);

    const TriggerComponent = <div className="category-menu-expandable-trigger" >
        <img alt="logo" src={RLS_Protect_Logo} />
        <img alt="expand" src={isMenuOpen ? baseline_expand_less : baseline_expand_more} className="category-menu-expandable-arrow" />
    </div>

    return <Collapsible trigger={TriggerComponent} easing="ease-in" onTriggerOpening={() => setIsMenuOpen(true)}
                        onTriggerClosing={() => setIsMenuOpen(false)} open={isMenuOpen}>
        <br />
        <div className="category-menu-collapsible-wrapper" >
            {p.categoryMenuItems.map(item => item)}
        </div>
    </Collapsible>
}

function GeneralUserMenu(p: { categoryMenuItems: JSX.Element[] }) {
    const { loginInfo } = useAuthService();
    const nav = useNavigate();
    const {showModal} = useCustomModal();

    return <div className="user-menu-wrapper" style = {{marginTop: 0}}>
        <div className="inner-margin-user-menu">
            <CategoryMenuExpandable categoryMenuItems={p.categoryMenuItems} />
        </div>
        <div className="horizontal-line-menu"></div>
        <button className="CIM-menu-button"
        onClick={() => showModal(RlsProtectUnavailableModal, {})}>
            <img alt="logo" src={RLS_Protect_Icon} height={32} onClick={() => {
                showModal(RlsProtectUnavailableModal, {})
            }}/>
            <p className={"CIM-menu-button-text"}><b>RLS</b> CIM</p>
        </button>
        <div className="horizontal-line-menu" ></div>
        <div className="inner-margin-user-menu">
            <img alt="logo" src={RLS_Reveal_Logo} onClick={() => {
                const expireTime = localStorage.getItem("expireTime");
                const timeLeft = -moment().diff(JSON.parse(expireTime!)) / 1000 / 60 // minutes
                setCookie('session', loginInfo?.cognito?.AccessToken!, timeLeft);
                setCookie('tenant', loginInfo?.tenant?.schema!, timeLeft);
                window.open("https://boilerplate.app-dev.rlsciences.com", "_blank");
            }}/>
        </div>
        <div className="horizontal-line-menu" ></div>
        <div className="inner-margin-user-menu" >
            <CategoryMenuItem isDisabled text="Reporting" onPress={() => { }} />
            <CategoryMenuItem isDisabled={false} text="Support & Feedback"
                              onPress={() => nav("/app/user/support-feedback")} />
        </div>
    </div>
}

function WorkflowMenu(p: { backHandler: () => void }) {

    const auth = useAuthService();
    const nav = useNavigate();
    const location = useLocation();
    const { showModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const [selectedTabButton, setSelectedTabButton] =
        useState<"My Tasks" | "Master Patterns Library" | "Project Templates" | "Projects" | "Search Pattern Generator">(auth.hasUserRoleAccess() ? "My Tasks" : "Projects");
    const [projectSelected, setProjectSelected] = useState(false);
    const initProjects = useRef<Project[]>([]);
    const totalProjectsIds = useRef<number[] | null>(null);
    const selectedProject = useAppSelector((state) => selectedProjectSelector(state));
    const loadedProjects = useAppSelector((state) => loadedProjectsSelector(state));
    const projects = useAppSelector((state) => projectsSelector(state));
    const loadedTemplates = useAppSelector((state) => projectTemplatesLoadedSelector(state));
    const templates = useAppSelector((state) => projectTemplatesSelector(state));
    const loadedPatternSets = useAppSelector((state) => PatternSetsSelector(state))

    const [hoverSimilarTrials, setHoverSimilarTrials] = useState(false);
    const [toggleExpand, setToggleExpand] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        if (!loadedProjects) {
            fetchProjects(controller.signal, dispatch, totalProjectsIds.current, initProjects.current);
            if (auth.hasPMRoleAccess()) {
                fetchTemplates(controller.signal, dispatch);
            }
            fetchUsers(controller.signal, dispatch);
            fetchCustomFields(controller.signal, dispatch);
        }
        return () => controller.abort();
    }, []);

    useEffect(() => {
        if (!loadedProjects && projects.length > 0 && projects[0].taskLists) {
            dispatch(setProjectsLoaded(true));
        }
    }, [projects]);

    useEffect(() => {
        const controller = new AbortController();
        fetchPatternSets(controller.signal, dispatch)
        return () => controller.abort();
    }, [])

    useEffect(() => {
        if (location.pathname.startsWith("/app/user/workflow/my-tasks")) {
            setSelectedTabButton("My Tasks");
            deselectProjects();
        }
        if (location.pathname.startsWith("/app/user/workflow/master-patterns-library")) {
            setSelectedTabButton("Master Patterns Library");
            deselectProjects();
        }
        if (location.pathname.startsWith("/app/user/workflow/project-template")) {
            setSelectedTabButton("Project Templates");
            deselectProjects();
        }
        if (location.pathname.startsWith("/app/user/workflow/projects/")) {
            setSelectedTabButton("Projects");
            setProjectSelected(true);
            setToggleExpand(true);
        }
        if (location.pathname === "/app/user/workflow/projects") {
            setSelectedTabButton("Projects");
            setProjectSelected(true);
            setToggleExpand(true);
        }

        if (location.pathname.startsWith("/app/user/workflow/task-details") && !selectedProject) {
            const taskDetailId = +location.pathname.replace("/app/user/workflow/task-details/", "")
                .split("/")[0];
            let isTemplate = false;
            if (auth.hasPMRoleAccess() && loadedTemplates && getProjectByTaskId(templates, taskDetailId)) {
                setSelectedTabButton("Project Templates");
                deselectProjects();
                isTemplate = true;
            }
            if ((loadedTemplates || !auth.hasPMRoleAccess()) && !isTemplate && loadedProjects) {
                const project: Project | undefined = getProjectByTaskId(projects, taskDetailId) as Project;
                if (project) {
                    dispatch(selectProject(project.id));
                    setSelectedTabButton("Projects");
                    setProjectSelected(false);
                    setToggleExpand(true);
                }
            }
        }
}, [location.pathname, loadedTemplates, loadedProjects, projects, templates])

const deselectProjects = () => {
    setProjectSelected(false);
    dispatch(selectProject(undefined));
}

return <div>
    <div className="workflow-menu-header-wrapper">
        <div className="workflow-menu-header" style = {{marginTop: -21}}>
            <img alt="back" src={baseline_arrow_back} className="workflow-menu-back-button" onClick={p.backHandler} />
            <span className="workflow-menu-header-title">Project Management</span>
        </div>
    </div>
    {auth.hasUserRoleAccess() && <TabButton isSelected={selectedTabButton === "My Tasks"}
               text="My Tasks"
               onPress={() => {
                   setSelectedTabButton("My Tasks");
                   nav("/app/user/workflow/my-tasks");
               }} />}
    {auth.hasPMRoleAccess() && <TabButton isSelected={selectedTabButton === "Master Patterns Library"}
                    text="Master Patterns Library"
                    onPress={() => {
                        setSelectedTabButton("Master Patterns Library");
                        nav("/app/user/workflow/master-patterns-library");
                    }} />}

    {auth.hasPMRoleAccess() && <TabButton isSelected={selectedTabButton === "Project Templates"}
               text="Project Templates"
               onPress={() => {
                   setSelectedTabButton("Project Templates");
                   nav("/app/user/workflow/project-templates");
               }} />}
    {/* The similar trials app does not work right now. Add this tab back in when we fix it. */}
        {/*<div*/}
        {/*    onMouseEnter={() => setHoverSimilarTrials(true)}*/}
        {/*    onMouseLeave={() => setHoverSimilarTrials(false)}*/}
        {/*    style={{ paddingTop: 10, paddingBottom: 10, backgroundColor: hoverSimilarTrials ? "#F5F6F7" : "white" }}*/}
        {/*>*/}
        {/*    <a*/}
        {/*        className="tab-button-menu-text" style={{ padding: 10 }}*/}
        {/*        href={process.env.REACT_APP_SIMILAR_TRIALS_URL} target="_blank">Similar Trials*/}
        {/*    </a>*/}
        {/*</div>*/}
    <TabButton isSelected={selectedTabButton === "Search Pattern Generator"}
               text="Search Pattern Generator"
               onPress={() => {
                   setSelectedTabButton("Search Pattern Generator");
                   nav("/app/user/workflow/search-pattern-generator");
               }} />
    <TabButton isSelected={projectSelected}
               text="Projects"
               expandable={true}
               isExpanded={projectSelected || selectedTabButton === "Projects"}
               onPress={() => {
                   setToggleExpand(!toggleExpand)
                    setSelectedTabButton("Projects");
                    dispatch(selectProject(undefined));
                    if (location.pathname !== "/app/user/workflow/projects/") {
                       nav("/app/user/workflow/projects");
                    }
               }} />
    {selectedTabButton === "Projects" && toggleExpand && <div style={{ display: "flex", flexDirection: "column" }}>
        {auth.hasPMRoleAccess() && <Button variant="contained" color="secondary"
                                           style={{ margin: "16px 24px", width: 134 }}
                                           onClick={() => showModal(ProjectCreateWizard, {})}
        >
            ADD PROJECT
        </Button>} </div>}
</div>
}

function UserMenu() {
    const [selectedMenu, setSelectedMenu] = useState<"Workflow" | "Docs" | "Risk">();
    const nav = useNavigate();
    const location = useLocation();
    const auth = useAuthService();
    const { showModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const accountList = localStorage.getItem("accountList");

    useEffect(() => {
        dispatch(setAccountList(JSON.parse(accountList!)));
    }, [accountList])

    useEffect(() => {
        if (location.pathname.startsWith("/app/user/workflow/")) {
            setSelectedMenu("Workflow");
        } else {
            setSelectedMenu(undefined);
        }
    }, [location.pathname])

    const categoryMenuItemsArray = [
        <CategoryMenuItem key="Workflow" isDisabled={false} text="Project Management" onPress={() => {
            setSelectedMenu("Workflow");
            nav(auth.hasUserRoleAccess() ? "/app/user/workflow/my-tasks" : "/app/user/workflow/projects");
        }} />,
        <CategoryMenuItem key="Docs" isDisabled={!auth.hasDocsAccess() || !auth.hasUserRoleAccess()} text="RLS Protect Docs"
            onPress={() => {
                nav("/app/user/workflow/my-tasks");
        }} />,
        <CategoryMenuItem key="Risk" isDisabled text="RLS Protect Risk"
            onPress={() => showModal(RlsProtectUnavailableModal, {})} />
    ]

    return <div className="menu-wrapper">
        {selectedMenu === "Workflow" ? <WorkflowMenu backHandler={() => {
                setSelectedMenu(undefined);
                nav("/app/user/landing-page");
            }} /> :
            <GeneralUserMenu categoryMenuItems={categoryMenuItemsArray} />
        }
    </div>
}

export function Menu() {
    const { viewPerspective } = useAuthService();
    const impersonate = localStorage.getItem("impersonate");
    if (viewPerspective === ViewPerspective.SUPERADMIN && !impersonate) return <AdminMenu />
    // else if (viewPerspective === ViewPerspective.USER) return <UserMenu />
    else return <UserMenu />
}
