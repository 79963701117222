import React, {useContext, useEffect, useMemo, useState} from 'react';
import {RedactionPanel} from './RedactionPanel';
import { RedactionPanelContext, RedactionPanelProvider } from './RedactionPanelContext';
import WebViewerContext from "../../../contexts/webviewer-context";
import {defaultRedactionTypes} from "../../../constants";

export const RedactionPanelContainer = (props) => {
  const {redactionSearchPatterns, selectedTab} = props
  const { setInstance,instance } = useContext(WebViewerContext);
  const [redactionAnnotationsList, setRedactionAnnotationsList] = useState([])

  useEffect(() => {
    const onAnnotationChanged = () => {
      setRedactionAnnotationsList(loadRedactionAnnotations(instance));
    }

    if (instance) {
      setRedactionAnnotationsList(loadRedactionAnnotations(instance));
      const annotationManager = instance.Core.annotationManager;
      annotationManager.addEventListener('annotationChanged', onAnnotationChanged)
    }
    return () => {
      instance.Core.annotationManager.removeEventListener('annotationChanged', onAnnotationChanged)
    }
  }, [instance]);

  const redactionTypesDictionary = useMemo(() => {
    const storedRedactionTypes = Object.keys(redactionSearchPatterns).reduce((map, key) => {
      const { label, type, icon } = redactionSearchPatterns[key];
      map[type] = {
        label,
        icon
      };
      return map;
    }, {});

    return { ...storedRedactionTypes, ...defaultRedactionTypes };
  }, [redactionSearchPatterns]);

  const deleteCheckedRedactionAnnotations = () => {
    const checkedRedactions = redactionAnnotationsList.filter(redaction => redaction.markChecked)
    instance.Core.annotationManager.deleteAnnotations(checkedRedactions);
  };

  const applyCheckedRedactions = () => {
    const checkedRedactions = redactionAnnotationsList.filter(redaction => redaction.markChecked)
    instance.Core.documentViewer.trigger('processStarted', 'redact')
    instance.Core.annotationManager.applyRedactions(checkedRedactions).then(r =>
      {instance.Core.documentViewer.trigger('processEnded', 'applyRedactions')}
    ).catch((e) => {
      console.log(e)
      instance.Core.documentViewer.trigger('processEnded', 'applyRedactionsError')
    })
  };

  const display = 'block'
  const style =  { width: `100%`, minWidth: `100px`, height: '100%' };

  const { isRedactionSearchActive } = useContext(RedactionPanelContext);

  return <div
      className="Panel RedactionPanel"
      style={{...style, display}}
    >
    <RedactionPanelProvider>
      <RedactionPanel
        redactionAnnotations={redactionAnnotationsList}
        redactionTypesDictionary={redactionTypesDictionary}
        applyCheckedRedactions={applyCheckedRedactions}
        deleteCheckedRedactionAnnotations={deleteCheckedRedactionAnnotations}
        selectedTab={selectedTab}
      />
    </RedactionPanelProvider>
    </div>
};

const RedactionPanelContainerWithProvider = () => {
  return (
    <RedactionPanelProvider>
      <RedactionPanelContainer />
    </RedactionPanelProvider>
  );
};

export default RedactionPanelContainerWithProvider;

export const loadRedactionAnnotations = (instance) => {
  return instance.Core.annotationManager.getAnnotationsList().filter((annotation) => annotation instanceof instance.Core.Annotations.RedactionAnnotation)
}
