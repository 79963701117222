import React, {useEffect, useRef, useState} from 'react';
import {useCustomModal} from "./custom-message-modal";
import ModalIconContent from "./modal-content/modal-icon-content";

//This asks the user to choose a match category for the mark they just added.
const UnsavedWarningModal = (props : { cancelPropagation: () => void, discardChanges: ()=>void, continueChanges:()=>void, unsavedDocNames: string[] }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);
    const onSubmit = () => {
        props.cancelPropagation()
        hideModal()
    }


    return <div ref={modalRef} style={{
        position: "relative",
        width: 400, display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        <h4 style={{fontWeight: "bold"}}>Warning</h4>
        <ModalIconContent/>
        <p>You have unsaved changes in these documents:</p>
        <div>
            {props.unsavedDocNames.map((docName, index) => (
                <p key={index}>{docName}</p>
            ))}
        </div>
        <div style={{marginTop: "10px"}}>
            <button onClick={() => {
                hideModal();
                props.continueChanges()
            }} className={"sanitize-first-modal"} style={{margin: "0px 10px", backgroundColor: '#e60024'}}>Cancel
            </button>
            <button onClick={() => {
                hideModal();
                props.discardChanges()
            }} className={"sanitize-first-modal"} style={{backgroundColor: "#e86122"}}>Discard Changes
            </button>
            <button onClick={() => {
                hideModal();
                props.cancelPropagation()
            }} className={"sanitize-first-modal"} style={{margin: "0px 10px"}}>Save Current Document
            </button>
        </div>

    </div>


};

export default UnsavedWarningModal;
