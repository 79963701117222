import React from 'react';
import {Popover, SxProps, Typography} from "@mui/material";
import Button from "@mui/material/Button";
const PopOver=({content, buttonText, style}: {content: string; buttonText: string, style: SxProps})=>{
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return(
        <>
            <Button
                color="primary"
                sx={style}
                variant="outlined"
                style={{width: "fit-content", alignSelf: "center"}}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {buttonText}
            </Button>
            <Popover
                sx={{ pointerEvents: 'none' }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>{content}</Typography>
            </Popover></>
    )
}
export default PopOver