import {Button, Menu, MenuItem} from '@mui/material';
import React, {useContext, useState} from 'react';
import LoadTransformsContext from "../../../contexts/load-transforms-context";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


const LoadTransformsButton = (props: {closeMenuCallBackFunction: any}) => {
    const { loadTransformCallbacks, setLoadTransformCallbacks} = useContext(LoadTransformsContext)
    const loadTransformCallback = loadTransformCallbacks.risk
    const transformFromReportCallback = loadTransformCallbacks.report
    const transformToCategoryCallback = loadTransformCallbacks.category
    const transformFromLookupCallback = loadTransformCallbacks.lookup
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        props.closeMenuCallBackFunction()
    };

    return (
        <div>
            <MenuItem
                onClick={handleClick}
            >
                Load
                <ArrowForwardIosIcon className={"submenu-icon"}/>
            </MenuItem>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                className={'transform-menu'}
                style={{ zIndex: 99999 }}
            >
                <MenuItem onClick={() => {handleClose();loadTransformCallback();}}>RLS Protect Risk</MenuItem>
                <MenuItem onClick={() => {handleClose();transformFromReportCallback();}}>Transforms Report</MenuItem>
                <MenuItem onClick={() => {handleClose();transformToCategoryCallback();}}>Category Names</MenuItem>
                <MenuItem onClick={() => {handleClose();transformFromLookupCallback();}}>Lookup File</MenuItem>

            </Menu>
        </div>
    );
};

export default LoadTransformsButton;