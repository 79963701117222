import React, { useEffect, useRef, useState } from 'react';
import { useCustomModal } from "./custom-message-modal";
import ModalIconContent from "./modal-content/modal-icon-content";

//This asks the user to choose a match category for the mark they just added.
const SanitizeRemovalModal = (props: { onSelection: any, scanData: any }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);
    const [resultsData, setResultsData] = useState<any>([]);
    useEffect(() => {
        const finalResult: any = [];
        Object.keys(props.scanData).forEach((val: any) => {
            finalResult.push({
                checked: false,
                value: props.scanData[val],
                name: val
            })
        })
        setResultsData(finalResult);
    }, [props.scanData])

    return <div ref={modalRef} style={{
        position: "relative",
        width: 350, display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        <div>
        <h4>Sanitize Results</h4>
            <ModalIconContent/>
            <br/>
        {
            resultsData.filter((val:any)=>val.name === 'metadata' || val.name === 'bookmarks' || val.name === 'comments' || val.name === 'linkActions' || val.name === 'javaScriptAndSignatures',).map((val: any, index: number) => {
                const name = val.name === 'comments' ? 'comments and markups' : val.name === 'linkActions' ? 'links and actions' : val.name==='javaScriptAndSignatures' ? 'JavaScript and Signatures' : val.name
                return <div className='d-flex' key={index}>
                    <input type="checkbox" onChange={(e) => {
                        const cloned = [...resultsData];
                        cloned[index] = { ...cloned[index], checked: e.target.checked }
                        setResultsData(cloned);
                    }} checked={val.checked} /> {name} {val.value !== null &&`(${val.value})`}
                </div>
            })
        }
        <br/>
<div style={{display:"felx", alignItems:"center", marginTop:"10px"}}>
    <button className={"sanitizer-second-modal"} style={{marginRight:"10px", backgroundColor:"#e60024"}} onClick={() => {
        hideModal()
    }}>Cancel</button>
    <button className={"sanitizer-second-modal"} style={{marginRight:"10px", backgroundColor:"#0068dd"}} onClick={() => {
        props.onSelection(resultsData.filter((val: any) => {
                return val.checked
            }).map((val: any) => { return val.name })

        )
        hideModal()
    }}>Remove</button>

</div>


    </div>
    </div>


};

export default SanitizeRemovalModal;
